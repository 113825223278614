import { Form, Formik, FormikValues } from "formik";
import { Item, Select, TextInput, Text, useNotifications } from "@ilc-technology/luik";
import { LabelKey } from "../../Common/StoryblokTypes";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import IntlTelInput from "intl-tel-input/react";
import React, { useEffect, useState } from "react";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import * as Yup from "yup";
import { CustomTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import { ErrorCode, ErrorDetails } from "../../Common/Types";
import { MultiStepFormStepProps } from "./MultiStepForm";
import ErrorComponentWrapper from "../ErrorHandling/ErrorComponentWrapper";
import { CustomerServiceApi } from "../../apis/customerServiceApi";
import { convertToErrorDetails, logError } from "../../Common/services/ErrorService";
import {
  CreateRelatedContactRequest,
  CustomerRelationship,
  UpdateRelatedContactRequest,
} from "../../apis/generatedCustomerServiceApiClient";

type ParentDetailsForm = {
  contactId: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  isEmergencyContact: boolean;
};

const ParentDetails: React.FC<MultiStepFormStepProps> = ({ onFormValuesChange, complete, onNext, onError }) => {
  const { labels, relationships } = useDatasources();
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const nameofContactDetailsForm = nameofFactory<ParentDetailsForm>();
  const { session } = useSessionContext();
  const { user } = session;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);
  const customerServiceApi = new CustomerServiceApi();
  const notifications = useNotifications();
  const [initialRelatedContactDetails, setInitialRelatedContactDetails] = useState<ParentDetailsForm>({
    contactId: user.relatedContact?.id ?? null,
    contactFirstName: user.relatedContact?.firstName ?? "",
    contactLastName: user.relatedContact?.lastName ?? "",
    contactEmail: user.relatedContact?.email ?? "",
    contactPhone: user.relatedContact?.phone ?? "",
    contactRole: user.relatedContact?.role ?? "",
    isEmergencyContact: user.relatedContact?.isEmergencyContact ?? false,
  });

  const getRelatedContactInfo = () => {
    customerServiceApi
      .getRelatedContacts(session.user.accountId)
      .then(async (result) => {
        if (
          !result.items ||
          result.items.length === 0 ||
          result.items.filter((rc) =>
            [CustomerRelationship.Father, CustomerRelationship.Mother, CustomerRelationship.Guardian].includes(
              rc.role as CustomerRelationship
            )
          ).length === 0
        ) {
          setInitialRelatedContactDetails({
            contactId: null,
            contactFirstName: "",
            contactLastName: "",
            contactEmail: "",
            contactPhone: "",
            contactRole: "",
            isEmergencyContact: false,
          });
        } else if (onNext) {
          onNext();
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.CustomerDetailsFetchFailed);
        if (errorDetails?.responseStatusCode != 404) {
          logError(errorDetails);
          setLoadingError(errorDetails);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveParentDetailsAsync = async (values: ParentDetailsForm | FormikValues) => {
    trackEvent(session.opportunity.id, CustomTrackingEvent.SaveParentDetailsCheckout);

    const data: CreateRelatedContactRequest | UpdateRelatedContactRequest = {
      firstName: values.contactFirstName,
      lastName: values.contactLastName,
      email: values.contactEmail,
      phone: values.contactPhone,
      role: values.contactRole as CustomerRelationship,
      isEmergencyContact: values.isEmergencyContact,
    };

    try {
      if (values.contactId === null) {
        await customerServiceApi.addRelatedContact(session.user.accountId, data as CreateRelatedContactRequest);
      } else {
        await customerServiceApi.updateRelatedContact(
          session.user.accountId,
          values.contactId,
          data as UpdateRelatedContactRequest
        );
      }
    } catch (err: unknown) {
      notifications.addErrorNotification({
        title: labels[LabelKey.saveFailed],
        description: labels[LabelKey.saveFailedDescription],
      });
      if (onError) {
        onError();
      }
      throw err;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getRelatedContactInfo();
  }, []);

  const parentDetailValidationSchema = Yup.object({
    contactId: Yup.string().notRequired(),
    contactFirstName: Yup.string().required(),
    contactLastName: Yup.string().required(),
    contactEmail: Yup.string().email().required(),
    contactPhone: Yup.string().required(),
    contactRole: Yup.string().required(),
    isEmergencyContact: Yup.string().notRequired(),
  });

  return (
    <Formik
      initialValues={initialRelatedContactDetails}
      enableReinitialize={true}
      onSubmit={saveParentDetailsAsync}
      validationSchema={parentDetailValidationSchema}
      validateOnMount={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({ values, dirty, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, submitForm }) => {
        useEffect(() => {
          if (onFormValuesChange) {
            onFormValuesChange({
              dirty,
              disabled: isSubmitting || !isPhoneNumberValid || Object.entries(errors).length > 0 || !!loadingError,
              data: values,
              loading: isSubmitting,
            });
          }
        }, [isSubmitting, dirty, touched, values, isPhoneNumberValid, errors, loadingError]);

        useEffect(() => {
          if (complete && onNext) {
            if (dirty) {
              submitForm()
                .then(onNext)
                .catch(() => {
                  /* empty, ignore the error */
                });
            } else {
              onNext();
            }
          }
        }, [complete]);

        const validation = (value: boolean) => {
          return value ? "invalid" : "valid";
        };

        return (
          <Form>
            <ErrorComponentWrapper isLoading={isLoading} error={loadingError}>
              <div className="flex flex-col justify-between gap-2">
                <div className="flex-shrink-0">
                  <Text variant="heading-5-bold">{labels[LabelKey.parentDetails]}</Text>
                </div>
              </div>
              <div className="a-gap-sm mt-8 flex flex-col">
                <TextInput
                  data-testid="contactFirstName-input"
                  isRequired={true}
                  validationState={validation(!!(errors.contactFirstName && touched.contactFirstName))}
                  errorMessage={labels[LabelKey.validationMessage]}
                  name={nameofContactDetailsForm.contactFirstName}
                  label={labels[LabelKey.firstName]}
                  placeholder={labels[LabelKey.firstName]}
                  type="text"
                  value={values.contactFirstName}
                  onChange={handleChange}
                  isDisabled={isSubmitting}
                  onBlur={handleBlur}
                  touched={touched.contactFirstName}
                  autoFocus={!values.contactFirstName}
                />
                <TextInput
                  data-testid="contactLastName-input"
                  isRequired={true}
                  validationState={validation(!!(errors.contactLastName && touched.contactLastName))}
                  errorMessage={labels[LabelKey.validationMessage]}
                  name={nameofContactDetailsForm.contactLastName}
                  label={labels[LabelKey.lastName]}
                  placeholder={labels[LabelKey.lastName]}
                  type="text"
                  value={values.contactLastName}
                  onChange={handleChange}
                  isDisabled={isSubmitting}
                  onBlur={handleBlur}
                  touched={touched.contactLastName}
                />
                <TextInput
                  data-testid="contactEmail-input"
                  isRequired={true}
                  name={nameofContactDetailsForm.contactEmail}
                  validationState={validation(!!(errors.contactEmail && touched.contactEmail))}
                  errorMessage={labels[LabelKey.validationEmailMessage]}
                  label={labels[LabelKey.email]}
                  placeholder={labels[LabelKey.email]}
                  type="email"
                  value={values.contactEmail}
                  onChange={handleChange}
                  isDisabled={isSubmitting}
                  onBlur={handleBlur}
                  touched={touched.contactEmail}
                />
                <div
                  className={`tel-container a-rounded flex flex-col justify-center border border-neutral-300 
                        ${(errors.contactPhone && touched.contactPhone) || (touched.contactPhone && !isPhoneNumberValid) ? "tel-invalid" : ""} 
                        ${isSubmitting ? "tel-container-disabled" : ""}`}
                >
                  <IntlTelInput
                    data-testid="contactPhone-input"
                    initialValue={values.contactPhone}
                    onChangeValidity={(value: boolean) => {
                      setPhoneNumberValid(value);
                    }}
                    onChangeNumber={async (value) =>
                      await setFieldValue(nameofContactDetailsForm.contactPhone, value, true)
                    }
                    usePreciseValidation={true}
                    initOptions={{
                      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.1.1/build/js/utils.js",
                      containerClass: "tel",
                      nationalMode: false,
                      strictMode: false,
                      formatAsYouType: true,
                      customPlaceholder: function () {
                        return labels[LabelKey.phoneNumber];
                      },
                      initialCountry: "auto",
                      geoIpLookup: function (success) {
                        fetch("https://ipapi.co/json")
                          .then(function (res) {
                            return res.json();
                          })
                          .then(function (data) {
                            success(data.country_code);
                          })
                          .catch(function () {});
                      },
                    }}
                    inputProps={{
                      onBlur: handleBlur(nameofContactDetailsForm.contactPhone),
                      name: "contactPhone-input",
                    }}
                  />
                </div>
                <Select
                  data-testid="contactRelationship-select"
                  name="contactRelationshipCode-select"
                  label={labels[LabelKey.parentRelationship]}
                  className="mt-0"
                  defaultSelectedKey={values.contactRole}
                  validationState={validation(!!(errors.contactRole && touched.contactRole))}
                  errorMessage={labels[LabelKey.validationMessage]}
                  trackingInfo="track-select"
                  isRequired={true}
                  onSelectionChange={async (value) =>
                    await setFieldValue(nameofContactDetailsForm.contactRole, value, true)
                  }
                  disabled={isSubmitting}
                  touched={touched.contactRole}
                  onBlur={handleBlur}
                >
                  {Object.entries(relationships).map(([relationshipCode, relationshipName]) => (
                    <Item key={relationshipCode} value={relationshipCode}>
                      {labels["relationship_" + relationshipName] ?? relationshipName}
                    </Item>
                  ))}
                </Select>
              </div>
            </ErrorComponentWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ParentDetails;
