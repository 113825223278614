import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { AppConfig } from "../../AppConfig";

let rumClient: AwsRum | undefined;

export const getRumClient = () => {
  if (!rumClient) {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: AppConfig.rum.IDENTITY_POOL_ID,
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: true,
      };

      rumClient = new AwsRum(
        AppConfig.rum.APPLICATION_ID,
        AppConfig.rum.APPLICATION_VERSION,
        AppConfig.rum.APPLICATION_REGION,
        config
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }
  return rumClient;
};
