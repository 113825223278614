export enum ColorVariant {
  Black,
  Yellow,
  Green,
  YellowDark,
  GreenDark,
  RedDark,
  DefaultDark,
}

export class ColorConfiguration {
  textColor: string;
  bgColor: string;

  constructor(textColor: string, bgColor: string) {
    this.textColor = textColor;
    this.bgColor = bgColor;
  }
}

const Configuration: ColorConfiguration[] = [
  new ColorConfiguration("text-white", "bg-ink-black"),
  new ColorConfiguration("text-black", "bg-reservation-yellow"),
  new ColorConfiguration("text-white", "bg-success-default"),
  new ColorConfiguration("text-white", "bg-warning-dark"),
  new ColorConfiguration("text-white", "bg-success-dark"),
  new ColorConfiguration("text-white", "bg-danger-dark"),
  new ColorConfiguration("text-white", "bg-default-dark"),
];

export function getTextColorForVariant(variant: ColorVariant) {
  return Configuration[variant].textColor;
}

export function getBgColorForVariant(variant: ColorVariant) {
  return Configuration[variant].bgColor;
}
