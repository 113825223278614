import React from "react";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { ImportMarketFontCSS } from "@ilc-technology/luik";

const Fonts: React.FC = () => {
  const { activeLanguage } = useDatasources();
  return <ImportMarketFontCSS market={activeLanguage?.efcom ?? "en"} />;
};

export default Fonts;
