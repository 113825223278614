import React, { useMemo } from "react";
import { Text } from "@ilc-technology/luik";
import { Link } from "react-router-dom";
import { LineItem, SalesItemType } from "../../Common/Types";
import { ContentType, StandardTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { useQuoteContext } from "../../contexts/QuoteContext/QuoteContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { AppConfig } from "../../AppConfig";
import { LineItemIsExtraNight } from "../../Common/Helpers/LineItemHelper";
import { TextVariants } from "../../Common/LuikTypes";

interface LineItemSeeDetailsProps {
  lineItem: LineItem;
  color?: "text-gray-45" | "text-ink-black";
  variant?: TextVariants;
}

export const LineItemSeeDetails: React.FC<LineItemSeeDetailsProps> = ({
  lineItem,
  color = "text-ink-black",
  variant = "label-sm-bold",
}) => {
  const { labels, activeLanguage } = useDatasources();
  const { selected, quotesContentCache } = useQuoteContext();

  const AccommodationSeeDetailsLink = (accommodationName: string) =>
    `${AppConfig.api.atlasWebsite}/public/${activeLanguage?.atlas.toLowerCase()}/accommodation/${accommodationName}/fact-sheet`;

  const detailsLink = useMemo(() => {
    switch (lineItem.type) {
      case SalesItemType.Accommodation: {
        if (LineItemIsExtraNight(lineItem)) return undefined;
        const residenceCode = lineItem.code.split("-")[1];
        if (
          residenceCode &&
          quotesContentCache.destinations
            .get(lineItem.destination)
            ?.accommodationCodes?.find((code) => code === residenceCode)
        ) {
          return AccommodationSeeDetailsLink(residenceCode.toLowerCase());
        }
        return undefined;
      }
      default:
        return undefined;
    }
  }, [lineItem, quotesContentCache.destinations, activeLanguage]);

  if (!detailsLink) {
    return null;
  }

  return (
    <Link
      to={detailsLink}
      onClick={() => {
        if (lineItem.type == SalesItemType.Accommodation) {
          trackEvent(selected?.quoteData.opportunityUuid, StandardTrackingEvent.SelectContent, {
            content_type: ContentType.AccommodationDetails,
          });
        }
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Text className={`${color} underline`} variant={variant}>
        {labels[LabelKey.showDetails]}
      </Text>
    </Link>
  );
};
