import React, { ReactNode } from "react";
import { AppConfig } from "../../AppConfig";
import TopBar from "../TopBar/TopBar";
import { ImportMarketFontCSS, Text } from "@ilc-technology/luik";
import styles from "./ErrorPages.module.scss";
import { AdditionalInfo } from "../ErrorHandling/AdditionalInfo";
import { ErrorDetails, StringIndexableDictionary } from "../../Common/Types";
import {
  getErrorDetailsToDisplay,
  getErrorMessageToDisplay,
  getErrorTitleToDisplay,
} from "../../Common/services/ErrorService";

interface ErrorPageProps {
  children?: ReactNode;
  className?: string;
  isTopBarVisible?: boolean;
  errorDetails: ErrorDetails;
  labels?: StringIndexableDictionary<string>;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ children, className, isTopBarVisible = true, errorDetails, labels }) => {
  const title = getErrorTitleToDisplay(errorDetails, labels);
  const message = getErrorMessageToDisplay(errorDetails, labels);
  const additionalInfo = getErrorDetailsToDisplay(errorDetails);
  return (
    <div data-testid={errorDetails.code}>
      <ImportMarketFontCSS market={"en"} />

      {isTopBarVisible && (
        <ul>
          <div>
            <TopBar logo={{ filename: AppConfig.content.logoImageUrl }} />
          </div>
        </ul>
      )}

      <div className={styles.container}>
        <div className={`${styles.section} ${className}`}>
          <Text as="h1" variant="heading-5-bold" className={styles.title} data-testid="error-page-title">
            {title}
          </Text>

          {message && (
            <Text as="p" variant="paragraph-body" data-testid="error-page-message">
              {message}
            </Text>
          )}

          {children}

          <AdditionalInfo additionalInfo={additionalInfo} />
        </div>
      </div>
    </div>
  );
};

interface AuthorizationFailedErrorPageProps {
  errorDetails: ErrorDetails;
  labels?: StringIndexableDictionary<string>;
}

const AuthorizationFailedErrorPage: React.FC<AuthorizationFailedErrorPageProps> = ({ errorDetails, labels }) => {
  return (
    <ErrorPage errorDetails={errorDetails} labels={labels}>
      <Text as="p" variant={"paragraph-body"}>
        Here&apos;s what you can do:
      </Text>
      <ul className={styles.list}>
        <li>Reload the page</li>
        <li>Check your internet connection or try connecting to the Wi-Fi</li>
        <li>Try opening the page on a different browser or device</li>
      </ul>
      <Text as="p" variant={"paragraph-body"}>
        If the issue continues, please reach out to your EF Consultant for assistance
      </Text>
    </ErrorPage>
  );
};

interface TokenErrorPageProps {
  errorDetails: ErrorDetails;
  labels?: StringIndexableDictionary<string>;
}

const TokenErrorPage: React.FC<TokenErrorPageProps> = ({ errorDetails, labels }) => {
  const oldValue = errorDetails.details || {};
  oldValue.fallbackTitle = "Invalid credentials";
  errorDetails.details = oldValue;
  return (
    <ErrorPage errorDetails={errorDetails} className={styles.centered} labels={labels}>
      <Text as="p" variant={"paragraph-body"}>
        We&apos;re unable to open the page you requested.
        <br />
        Please click the link we&apos;ve shared with you to access your price quote(s).
      </Text>
    </ErrorPage>
  );
};

export { AuthorizationFailedErrorPage, TokenErrorPage };
