import { Divider, Text } from "@ilc-technology/luik";
import React from "react";
import Surface from "../Surface/Surface";
import TitleValueCell from "./TitleValueCell";
import { FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import { Money } from "../../Common/Types";

interface InfoCardProps {
  summary: string;
  destinationName: string;
  contentText?: string;

  amountLabel: string;
  amount: Money;

  topChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({
  destinationName,
  contentText,
  summary,
  amountLabel,
  amount,
  topChildren,
  bottomChildren,
}) => {
  const getBorderRadius = (top?: React.ReactNode, bottom?: React.ReactNode) => {
    if (top && bottom) {
      return "none";
    } else if (top && !bottom) {
      return "bottom";
    } else if (!top && bottom) {
      return "top";
    } else {
      return "all";
    }
  };

  return (
    <div>
      {topChildren}
      <Surface borderRadius={getBorderRadius(topChildren, bottomChildren)}>
        <div className="a-space-y-sm">
          <div className="a-space-y-sm">
            <Text variant="special-eyebrow" data-testid="psc-summary-text">
              {summary}
            </Text>
            <Text variant="heading-5-bold" data-testid="psc-destination-text">
              {destinationName}
            </Text>
            {contentText && (
              <Text variant="paragraph-small-body" data-testid="psc-content-text">
                {contentText}
              </Text>
            )}
          </div>
          <Divider light />
          <TitleValueCell leftTextValue={amountLabel} rightTextValue={FormattedMoney(amount)} testId="psc-total" />
        </div>
      </Surface>
      {bottomChildren}
    </div>
  );
};

export default InfoCard;
