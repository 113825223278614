import React, { useEffect, useState } from "react";
import { Accordion, Text } from "@ilc-technology/luik";
import { Button } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import {
  ApiRequestType,
  CourseContent,
  CourseIntensityUpgradeResponse,
  ErrorCode,
  Quote,
  Segment,
  SegmentType,
} from "../../Common/Types";
import Enrich from "../../Common/services/TextEnricher";
import CourseUpgradeModal from "../Modal/CourseUpgradeModal";
import { QuoteData } from "../../contexts/QuoteContext/QuoteContext";
import { AppConfig } from "../../AppConfig";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { GenerateHeaderInformation } from "../../Common/Helpers/ApiHelper";
import { fetchRetry } from "../../Common/services/FetchRetry";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../../Common/services/ErrorService";
import { JWT_API_KEY } from "../../Common/Constants";

interface UpgradeCourseProps {
  content?: CourseContent;
  isSaveInProgress: boolean;
  startsOpen: boolean;
  quote: Quote;
  quoteDraft: QuoteData;
}

const UpgradeCourse: React.FC<UpgradeCourseProps> = ({ content, isSaveInProgress, startsOpen, quote, quoteDraft }) => {
  const { labels } = useDatasources();
  const { session } = useSessionContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upgradeQuoteSegment, setUpgradeSegment] = useState<Segment | undefined>();
  const [sku, setSku] = useState<string>("");
  const courseSegments = quote.segments?.filter((segment) => segment.type === SegmentType.Course) ?? [];

  useEffect(() => {
    if (courseSegments.length > 0 && quoteDraft && !upgradeQuoteSegment) {
      fetchRetry(`${AppConfig.api.quotation}/quote-drafts/${quoteDraft.quoteData.id}/course-intensity-upgrade`, {
        method: ApiRequestType.GET,
        headers: GenerateHeaderInformation(session.jwtAccessToken, JWT_API_KEY),
      })
        .then((response) => returnJsonOrThrowError<CourseIntensityUpgradeResponse>(response))
        .then((result) => {
          setUpgradeSegment({
            price: { totalPrice: { amount: result.newPrice.amount, currency: result.newPrice.currency } },
            duration: courseSegments[0]?.duration,
          } as Segment);
          setSku(result.sku);
        })
        .catch((error) => {
          const e = convertToErrorDetails(error, ErrorCode.CourseUpgradeFetchFailed);
          if (e.responseStatusCode !== 404) {
            logError(e);
          }
        });
    }
  }, [quoteDraft.quoteData.id]);

  const enricherContext = {
    segment: upgradeQuoteSegment,
  };

  if (!content || !upgradeQuoteSegment) {
    return null;
  }

  return (
    <>
      <Accordion
        className="upgrade"
        startsOpen={startsOpen}
        intent="black"
        data={[
          {
            title: labels[LabelKey.learnFaster],
            children: (
              <div className="a-gap flex flex-col" data-testid="upgrade-course-component">
                <Text variant="paragraph-body">
                  {content.title} {content.lessonsPerWeek} {content.lessonsPerWeekText}
                </Text>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col">
                    <Text variant="label-md-bold">{Enrich(labels[LabelKey.segmentTotalPrice], enricherContext)}</Text>
                    <Text variant="label-sm" className="text-gray-45">
                      {Enrich(labels[LabelKey.segmentPricePerWeek], enricherContext)}
                    </Text>
                  </div>
                  <Button
                    data-testid="select-course-button"
                    isDisabled={isSaveInProgress}
                    size="base"
                    intent="primary-black"
                    iconName="arrow-right"
                    iconPosition="end"
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    {labels[LabelKey.selectButton]}
                  </Button>
                </div>
              </div>
            ),
          },
        ]}
      />
      {isModalOpen && (
        <CourseUpgradeModal
          isOpen={isModalOpen}
          closeHandler={() => setIsModalOpen(false)}
          content={content}
          sku={sku}
          duration={upgradeQuoteSegment.duration.value}
          totalPriceDifference={upgradeQuoteSegment.price.totalPrice}
          quote={quote}
          quoteDraft={quoteDraft.quoteData}
        />
      )}
    </>
  );
};

export default UpgradeCourse;
