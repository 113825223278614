import { AccountUpdateRequest, User } from "../../contexts/SessionContext/SessionContext";

export const convertToAccountUpdateRequest = (data: User): AccountUpdateRequest => {
  return {
    firstName: data.firstName,
    middleName: data.middleName || undefined,
    lastName: data.lastName,
    personDetails: {
      gender: data.gender || undefined,
      dateOfBirth: data.dateOfBirth || undefined,
      email: data.email || undefined,
      invoiceEmail: data.invoiceEmail || undefined,
      nationalityCountryCode: data.nationalityCountryCode,
      passportNumber: data.passportNumber || undefined,
      mobilePhone: data.mobilePhone,
      birthCountry: data.birthCountry,
    },
    addresses: {
      mailingAddress: {
        countryCode: data.country,
        street: data.street || undefined,
        postalCode: data.postalCode || undefined,
        city: data.city || undefined,
      },
    },
    latin: {
      firstName: data.firstNameLatin,
      lastName: data.lastNameLatin,
      middleName: data.middleNameLatin || undefined,
    },
    isFullUpdate: data.isFullUpdate,
  };
};
