import React, { useEffect, useState } from "react";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import moment from "moment";
import { TRUE } from "../../Common/Constants";
import "./StudentDetailsFullUpgrade.scss";
import "intl-tel-input/build/css/intlTelInput.css";
import ParentDetails from "./ParentDetails";
import StudentBasicDetails from "./StudentBasicDetails";
import MedicalDetails from "./MedicalDetails";
import MultiStepForm from "./MultiStepForm";
import ThankYou from "./ThankYou";
import { PaymentDetails } from "../../Common/Helpers/PaymentHelper";
import { Quote } from "../../Common/Types";

interface StudentDetailsFullUpgradeProps {
  paymentDetails: PaymentDetails;
  quote: Quote;
  onComplete: () => void;
}

const StudentDetailsFullUpgrade: React.FC<StudentDetailsFullUpgradeProps> = ({ paymentDetails, quote, onComplete }) => {
  const { featureSettings } = useDatasources();
  const { session } = useSessionContext();
  const { user } = session;
  const [needParentDetails, setNeedParentDetails] = useState(true);
  const [needMedicalDetails, setNeedMedicalDetails] = useState(true);

  const handleIfNeedParentDetails = (dateOfBirth: string) => {
    const userAge = moment(new Date()).diff(moment(dateOfBirth), "years");
    if (userAge < 18 && featureSettings.isParentDetailsEnabled === TRUE) {
      setNeedParentDetails(true);
    } else {
      setNeedParentDetails(false);
    }
  };

  useEffect(() => {
    handleIfNeedParentDetails(user.dateOfBirth);
    if (featureSettings.isMedicalDetailsEnabled === TRUE) {
      setNeedMedicalDetails(true);
    } else {
      setNeedMedicalDetails(false);
    }
  }, [user.dateOfBirth, user.relatedContact, featureSettings]);

  return (
    <MultiStepForm id={"studentDetailsForm"} data-testid="studentDetails-form" onComplete={onComplete}>
      <StudentBasicDetails quote={quote} />
      {needParentDetails && <ParentDetails quote={quote} />}
      {needMedicalDetails && <MedicalDetails quote={quote} />}
      <MultiStepForm.Complete>
        <ThankYou paymentDetails={paymentDetails} quote={quote} />
      </MultiStepForm.Complete>
    </MultiStepForm>
  );
};

export default StudentDetailsFullUpgrade;
