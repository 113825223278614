import React from "react";
import TitleValueCell, { TwoValuesCellOptionsProps } from "../../BaseComponents/TitleValueCell";
import { TextVariants } from "../../../Common/LuikTypes";

interface PaymentSummaryCellProps {
  leftTextValue?: string;
  leftTextVariant?: TextVariants;
  rightTextValue?: string;
  rightTextVariant?: TextVariants;
  testId?: string;
  leftValueChildren?: React.ReactNode;
  rightValueChildren?: React.ReactNode;
  className?: string;
  options?: TwoValuesCellOptionsProps;
}

const PaymentSummaryCell: React.FC<PaymentSummaryCellProps> = ({
  leftTextValue,
  rightTextValue,
  leftValueChildren,
  rightValueChildren,
  className = "md:my-4 my-2 md:flex md:justify-between md:self-stretch",
  testId = "text",
  options,
}) => {
  return (
    <TitleValueCell
      leftTextValue={leftTextValue}
      leftTextVariant="label-md-bold"
      rightTextValue={rightTextValue}
      rightTextVariant="paragraph-body"
      leftValueChildren={leftValueChildren}
      rightValueChildren={rightValueChildren}
      className={className}
      testId={testId}
      options={options}
    />
  );
};

export default PaymentSummaryCell;
