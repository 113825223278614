import React, { useEffect } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import SalesUserInfo from "../../SalesUserInfo/SalesUserInfo";
import { SALESFORCE_INTERGRATION_USER_MAIL } from "../../../Common/Constants";
import { useDatasources, useStoryblokSlug } from "../../../contexts/StoryblokContext/StoryblokContext";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import QuoteListBox from "../../QuoteList/QuoteListBox/QuoteListBox";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import ContentLayout from "../../BaseComponents/ContentLayout";
import { Text } from "@ilc-technology/luik";
import { LabelKey } from "../../../Common/StoryblokTypes";

const HomePage: React.FC = () => {
  const { activeLanguage, labels } = useDatasources();
  const { session } = useSessionContext();
  const { salesUser } = session;
  const { quotes, isQuoteListLoading, quotesContentCache, setupError, setSelectedQuoteById } = useQuoteContext();

  const bannerStory = useStoryblokSlug("home-banner", activeLanguage);
  const bannerCardStory = useStoryblokSlug("home-banner-card", activeLanguage);

  useEffect(() => {
    setSelectedQuoteById(undefined);
    window.scrollTo(0, 0);
  }, []);

  return (
    <ContentLayout
      banner={bannerStory?.content && <StoryblokComponent blok={bannerStory.content} key={bannerStory.content._uid} />}
      topStaticCard={
        bannerCardStory?.content && (
          <StoryblokComponent blok={bannerCardStory.content} key={bannerCardStory.content._uid} />
        )
      }
      bottomFullScreenPanel={
        salesUser?.firstName &&
        salesUser?.lastName &&
        salesUser.email !== SALESFORCE_INTERGRATION_USER_MAIL && (
          <SalesUserInfo
            title={salesUser.title}
            firstName={salesUser.firstName}
            lastName={salesUser.lastName}
            description={salesUser.aboutMe}
            phone={salesUser.phone}
            email={salesUser.email}
            imageUrl={salesUser.photoUrl}
          />
        )
      }
    >
      <>
        <div className="flex w-full flex-col" id="quoteList">
          <ErrorComponentWrapper isLoading={isQuoteListLoading} error={setupError}>
            <>
              {labels && (
                <Text variant="heading-1" className="mx-auto text-center">
                  {labels[LabelKey.myPriceQuotations]}
                </Text>
              )}
              <QuoteListBox quotes={quotes} quotesContent={quotesContentCache} />
            </>
          </ErrorComponentWrapper>
        </div>
      </>
    </ContentLayout>
  );
};

export default HomePage;
