import React from "react";
import { Text } from "@ilc-technology/luik";
import { LabelKey } from "../../../../Common/StoryblokTypes";
import { useDatasources, useStoryblokSlug } from "../../../../contexts/StoryblokContext/StoryblokContext";
import { StoryblokComponent } from "@storyblok/react";
import Surface from "../../../Surface/Surface";
import { PaymentStatus } from "../../../../Common/Types";

interface WhatsNextProps {
  paymentStatus: PaymentStatus | null;
}

const WhatsNext: React.FC<WhatsNextProps> = ({ paymentStatus }) => {
  const { labels, activeLanguage } = useDatasources();
  const paymentSummaryWhatsNextStoryBlokFolderSlug = "payment-summary-what-s-next";
  const slug =
    paymentStatus === PaymentStatus.PaymentSuccess
      ? `${paymentSummaryWhatsNextStoryBlokFolderSlug}/success`
      : paymentStatus === PaymentStatus.ReservationSuccess
        ? `${paymentSummaryWhatsNextStoryBlokFolderSlug}/reservation`
        : paymentStatus === PaymentStatus.PaymentProcessed
          ? `${paymentSummaryWhatsNextStoryBlokFolderSlug}/pending`
          : `${paymentSummaryWhatsNextStoryBlokFolderSlug}/default`;

  const whatIsNextBlok = useStoryblokSlug(slug, activeLanguage);

  return (
    whatIsNextBlok?.content && (
      <div className="a-gap-y flex flex-col">
        <Surface>
          <div
            className="flex-start a-gap-y-sm flex flex-col justify-center self-stretch"
            data-testid="whatIsNext-blok"
          >
            <Text variant="heading-5-bold">{labels[LabelKey.whatsNext]}</Text>
            <StoryblokComponent blok={whatIsNextBlok.content} key={whatIsNextBlok.content._uid} />
          </div>
        </Surface>
      </div>
    )
  );
};

export default WhatsNext;
