import { LabelKey } from "./StoryblokTypes";
import { StringIndexableDictionary } from "./Types";

export const getWeekLabel = (labels: StringIndexableDictionary<string>, totalDuration: number) => {
  const weeksLabel =
    totalDuration === 1
      ? labels[LabelKey.durationWeek]
      : totalDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];

  return weeksLabel;
};
