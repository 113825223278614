import React, { useEffect, useState } from "react";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { useQuoteDetailsContext } from "../../../contexts/QuoteDetailsContext";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import Payment from "./Payment";
import { ErrorCode, ErrorDetails, PaymentInfoStatus } from "../../../Common/Types";
import { getQuotePaymentStatus } from "../../../Common/services/PaymentsService";

const PaymentPage: React.FC = () => {
  const { selected, isQuoteListLoading, setupError } = useQuoteContext();
  const { loadingQuote, loadingQuoteError, reservationPrice } = useQuoteDetailsContext();
  const [paymentPageError, setPaymentPageError] = useState<ErrorDetails | undefined>(undefined);

  useEffect(() => {
    if (getQuotePaymentStatus(selected?.quoteData) === PaymentInfoStatus.Paid) {
      setPaymentPageError({
        code: ErrorCode.TryingToPayAlreadyPaidQuote,
        details: {
          fallbackMessage: "Quote has been already paid.",
        },
      });
    } else {
      setPaymentPageError(undefined);
    }
  }, [selected]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorComponentWrapper
      isLoading={loadingQuote || isQuoteListLoading || !selected || !reservationPrice}
      error={loadingQuoteError || setupError || paymentPageError}
    >
      {selected && reservationPrice && <Payment quote={selected.quoteData} reservationPrice={reservationPrice} />}
    </ErrorComponentWrapper>
  );
};

export default PaymentPage;
