import { useState, useEffect } from "react";

interface WindowSize {
  width: number;
  height: number;
  scrollHeight: number;
}

/**
 * Custom hook to get the window size.
 * @returns {WindowSize} Object containing the width and height of the window.
 */
export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
    scrollHeight: document.body.scrollHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      scrollHeight: document.body.scrollHeight,
    });
  };

  const observer = new MutationObserver(() => handleResize());

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, []);

  return windowSize;
}
