import { Checkbox, Text, TextInput, useNotifications } from "@ilc-technology/luik";
import { Form, Formik, FormikValues } from "formik";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import React, { useEffect, useState } from "react";
import { Allergies, DietaryNeeds, Disabilities, MedicalNeeds, CustomerServiceApi } from "../../apis/customerServiceApi";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { ErrorCode, ErrorDetails } from "../../Common/Types";
import { convertToErrorDetails, logError } from "../../Common/services/ErrorService";
import InformationBox from "../InformationBox/InformationBox";
import ErrorComponentWrapper from "../ErrorHandling/ErrorComponentWrapper";
import { CustomTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import { MedicalsPutRequest } from "../../apis/generatedCustomerServiceApiClient";
import { MultiStepFormStepProps } from "./MultiStepForm";

interface MedicalDetailsForm {
  allergies: string[];
  otherAllergies: string;
  otherDietaryNeed: string;
  dietaryNeeds: string[];
  medications: string;
  disabilities: string[];
  otherDisabilityNote: string;
  otherMedicalNeeds: string[];
  otherMedicalNote: string;
}

const MedicalDetails: React.FC<MultiStepFormStepProps> = ({ onFormValuesChange, onNext, onError, complete }) => {
  const { labels } = useDatasources();
  const nameOfMedicalDetailsForm = nameofFactory<MedicalDetailsForm>();
  const customerServiceApi = new CustomerServiceApi();
  const [initialMedicalDetails, setInitialMedicalDetails] = useState<MedicalDetailsForm>({
    allergies: [],
    dietaryNeeds: [],
    disabilities: [],
    otherMedicalNeeds: [],
    medications: "",
    otherAllergies: "",
    otherDietaryNeed: "",
    otherDisabilityNote: "",
    otherMedicalNote: "",
  });

  const allergyItems: { key: Allergies; label: string }[] = [
    { key: "PETS_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_pets] },
    { key: "POLLEN_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_pollen] },
    { key: "NUTS_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_nuts] },
    { key: "INSECT_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_insect] },
    { key: "DUST_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_dust] },
    { key: "PENICILLIN_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_penicilin] },
  ];

  const dietaryItems: { key: DietaryNeeds; label: string }[] = [
    { key: "HALAL", label: labels[LabelKey.medicalDetails_dietary_halal] },
    { key: "GLUTEN_FREE", label: labels[LabelKey.medicalDetails_dietary_glutenFree] },
    { key: "VEGAN", label: labels[LabelKey.medicalDetails_dietary_vegan] },
    { key: "VEGETARIAN", label: labels[LabelKey.medicalDetails_dietary_vegetarian] },
    { key: "KOSHER", label: labels[LabelKey.medicalDetails_dietary_kosher] },
    { key: "LACTOSE_FREE", label: labels[LabelKey.medicalDetails_dietary_lactoseFree] },
  ];

  const disabilityItems: { key: Disabilities; label: string }[] = [
    { key: "USES_A_WHEELCHAIR", label: labels[LabelKey.medicalDetails_disability_usesWheelchair] },
    { key: "VISUALLY_IMPAIRED", label: labels[LabelKey.medicalDetails_disability_visuallyImpaired] },
    { key: "DEAF", label: labels[LabelKey.medicalDetails_disability_deaf] },
  ];

  const medicalNeeds: { key: MedicalNeeds; label: string }[] = [
    { key: "EPILEPTIC", label: labels[LabelKey.medicalDetails_medicalNeeds_epileptic] },
    { key: "ASTHMATIC", label: labels[LabelKey.medicalDetails_medicalNeeds_asthmatic] },
    { key: "DIABETIC", label: labels[LabelKey.medicalDetails_medicalNeeds_diabetic] },
  ];

  const [updateBlockingReason, setUpdateBlockingReason] = useState<"BookingStartingTooSoon" | null>(null);
  const [allergies, setAllergies] = useState<string[]>([]);
  const [dietaryNeeds, setDietaryNeeds] = useState<string[]>([]);
  const [disabilities, setDisabilities] = useState<string[]>([]);
  const [otherMedicalNeeds, setOtherMedicalNeeds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);
  const { session } = useSessionContext();
  const notifications = useNotifications();

  const getMedicalInfo = async () => {
    try {
      const result = await customerServiceApi.getMedicals(session.user.accountId);
      setInitialMedicalDetails({
        allergies: result.allergies || [],
        dietaryNeeds: result.dietaryNeeds || [],
        disabilities: result.disabilities || [],
        medications: result.medications || "",
        otherAllergies: result.allergyNotes || "",
        otherDietaryNeed: result.dietaryNotes || "",
        otherDisabilityNote: result.disabilityNotes || "",
        otherMedicalNeeds: result.medicalNeeds || [],
        otherMedicalNote: result.medicalNotes || "",
      });
      setAllergies(result.allergies);
      setDietaryNeeds(result.dietaryNeeds);
      setDisabilities(result.disabilities);
      setOtherMedicalNeeds(result.medicalNeeds);
      setUpdateBlockingReason(result.updateBlockingReason);
    } catch (error: unknown) {
      const errorDetails = convertToErrorDetails(error, ErrorCode.MedicalDetailsFetchFailed);
      if (errorDetails?.responseStatusCode != 404) {
        logError(errorDetails);
        setLoadingError(errorDetails);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const saveMedicalDetailsAsync = async (values: MedicalDetailsForm | FormikValues) => {
    if (!isUpdateBlockedByBooking(updateBlockingReason)) {
      trackEvent(session.opportunity.id, CustomTrackingEvent.SaveMedicalDetailsCheckout);

      const upsertMedicalDetails = {
        flags: [...values.allergies, ...values.dietaryNeeds, ...values.disabilities, ...values.otherMedicalNeeds],
        otherMedicalNotes: values.otherMedicalNote,
        allergyNotes: values.otherAllergies,
        dietaryNotes: values.otherDietaryNeed,
        disabilityNotes: values.otherDisabilityNote,
        carriesMedication: values.medications,
      } as MedicalsPutRequest;

      try {
        return await customerServiceApi.upsertMedicals(session.user.accountId, upsertMedicalDetails);
      } catch (err: unknown) {
        notifications.addErrorNotification({
          title: labels[LabelKey.saveFailed],
          description: labels[LabelKey.saveFailedDescription],
        });
        if (onError) {
          onError();
        }
        throw err;
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getMedicalInfo();
  }, []);

  const handleAllergiesCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && allergies.find((item) => item === checkbox) === undefined) {
      setAllergies((prev) => prev.concat(checkbox));
    } else {
      setAllergies((prev) => prev.filter((item) => item !== checkbox));
    }
  };

  const handleDietaryNeedsCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && dietaryNeeds.find((item) => item === checkbox) === undefined) {
      setDietaryNeeds((prev) => prev.concat(checkbox));
    } else {
      setDietaryNeeds((prev) => prev.filter((item) => item !== checkbox));
    }
  };

  const handleDisabilityCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && disabilities.find((item) => item === checkbox) === undefined) {
      setDisabilities((prev) => prev.concat(checkbox));
    } else {
      setDisabilities((prev) => prev.filter((item) => item !== checkbox));
    }
  };

  const handleMedicalNeedsCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && otherMedicalNeeds.find((item) => item === checkbox) === undefined) {
      setOtherMedicalNeeds((prev) => prev.concat(checkbox));
    } else {
      setOtherMedicalNeeds((prev) => prev.filter((item) => item !== checkbox));
    }
  };

  const isUpdateBlockedByBooking = (updateBlockingReason: string | null) => {
    return updateBlockingReason === "BookingStartingTooSoon";
  };

  return (
    <Formik
      initialValues={initialMedicalDetails}
      enableReinitialize={true}
      onSubmit={saveMedicalDetailsAsync}
      validateOnMount={true}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({ values, dirty, touched, handleChange, handleBlur, isSubmitting, setFieldValue, submitForm }) => {
        useEffect(() => {
          if (onFormValuesChange) {
            onFormValuesChange({
              dirty,
              disabled: isSubmitting,
              data: values,
              loading: isSubmitting,
            });
          }
        }, [isSubmitting, dirty, touched, values]);

        useEffect(() => {
          if (complete && onNext) {
            if (dirty) {
              submitForm()
                .then(onNext)
                .catch(() => {
                  /* empty, ignore the error */
                });
            } else {
              onNext();
            }
          }
        }, [complete]);

        useEffect(() => {
          setFieldValue("allergies", allergies).catch((error) => {
            logError(convertToErrorDetails(error, ErrorCode.DataUpdateFailed));
          });
        }, [allergies]);
        useEffect(() => {
          setFieldValue("dietaryNeeds", dietaryNeeds).catch((error) => {
            logError(convertToErrorDetails(error, ErrorCode.DataUpdateFailed));
          });
        }, [dietaryNeeds]);
        useEffect(() => {
          setFieldValue("disabilities", disabilities).catch((error) => {
            logError(convertToErrorDetails(error, ErrorCode.DataUpdateFailed));
          });
        }, [disabilities]);
        useEffect(() => {
          setFieldValue("otherMedicalNeeds", otherMedicalNeeds).catch((error) => {
            logError(convertToErrorDetails(error, ErrorCode.DataUpdateFailed));
          });
        }, [otherMedicalNeeds]);

        return (
          <Form>
            <ErrorComponentWrapper isLoading={isLoading} error={loadingError}>
              <div className="flex flex-col justify-between gap-2">
                <div className="flex-shrink-0">
                  <Text variant="heading-5-bold">{labels[LabelKey.medicalDetails_allergies]}</Text>
                </div>
              </div>
              <div className="a-gap-sm mt-8 flex flex-col">
                {allergyItems.map((item: { key: string; label: string }) => (
                  <Checkbox
                    data-testid={`${item.key}-input`}
                    key={item.key}
                    value={item.key}
                    isSelected={values.allergies?.includes(item.key)}
                    onChange={(value) => handleAllergiesCheckboxOnchange(value.target.checked, item.key)}
                    isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
                <TextInput
                  id={nameOfMedicalDetailsForm.otherAllergies}
                  data-testid="medicalDetailsOtherAllergies-input"
                  label={labels[LabelKey.medicalDetails_allergy_other]}
                  placeholder={labels[LabelKey.medicalDetails_allergy_other]}
                  type="text"
                  name={nameOfMedicalDetailsForm.otherAllergies}
                  value={values.otherAllergies}
                  onChange={handleChange}
                  isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  onBlur={handleBlur}
                  touched={touched.otherAllergies}
                />
                <div className="mt-8 flex flex-col justify-between gap-2">
                  <div className="flex-shrink-0">
                    <Text variant="heading-5-bold">{labels[LabelKey.medicalDetails_dietaryNeeds]}</Text>
                  </div>
                  <div className="text-sm">
                    <Text variant="paragraph-body">{labels[LabelKey.medicalDetails_dietaryNeeds_description]}</Text>
                  </div>
                </div>
                {dietaryItems.map((item: { key: string; label: string }) => (
                  <Checkbox
                    id={item.key}
                    data-testid={`${item.key}-input`}
                    key={item.key}
                    value={item.key}
                    isSelected={values.dietaryNeeds?.includes(item.key)}
                    onChange={(value) => handleDietaryNeedsCheckboxOnchange(value.target.checked, item.key)}
                    isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
                <TextInput
                  id={nameOfMedicalDetailsForm.otherDietaryNeed}
                  label={labels[LabelKey.medicalDetails_dietary_other]}
                  placeholder={labels[LabelKey.medicalDetails_dietary_other]}
                  type="text"
                  data-testid="medicalDetailsOtherDietaryNeed-input"
                  name={nameOfMedicalDetailsForm.otherDietaryNeed}
                  value={values.otherDietaryNeed}
                  onChange={handleChange}
                  isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  onBlur={handleBlur}
                  touched={touched.otherDietaryNeed}
                />
                <div className="mt-8 flex flex-col justify-between gap-2">
                  <div className="flex-shrink-0">
                    <Text variant="heading-5-bold">{labels[LabelKey.medicalDetails_medicalNeeds]}</Text>
                  </div>
                  <div className="text-sm">
                    <Text variant="paragraph-body">{labels[LabelKey.medicalDetails_medicalNeeds_description]}</Text>
                  </div>
                </div>
                <TextInput
                  id={nameOfMedicalDetailsForm.medications}
                  data-testid="medicalDetailsMedication-input"
                  label={labels[LabelKey.medicalDetails_medicalNeeds_medications]}
                  placeholder={labels[LabelKey.medicalDetails_medicalNeeds_medications]}
                  type="text"
                  name={nameOfMedicalDetailsForm.medications}
                  value={values.medications}
                  onChange={handleChange}
                  isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  onBlur={handleBlur}
                  touched={touched.medications}
                />
                {disabilityItems.map((item: { key: string; label: string }) => (
                  <Checkbox
                    id={item.key}
                    data-testid={`${item.key}-input`}
                    key={item.key}
                    value={item.key}
                    isSelected={values.disabilities?.includes(item.key)}
                    onChange={(value) => handleDisabilityCheckboxOnchange(value.target.checked, item.key)}
                    isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
                <TextInput
                  id={nameOfMedicalDetailsForm.otherDisabilityNote}
                  data-testid="medicalDetailsOtherDisabilityNote-input"
                  label={labels[LabelKey.medicalDetails_disability_other]}
                  placeholder={labels[LabelKey.medicalDetails_disability_other]}
                  type="text"
                  name={nameOfMedicalDetailsForm.otherDisabilityNote}
                  value={values.otherDisabilityNote}
                  onChange={handleChange}
                  isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  onBlur={handleBlur}
                  touched={touched.otherDisabilityNote}
                />
                {medicalNeeds.map((item: { key: string; label: string }) => (
                  <Checkbox
                    id={item.key}
                    data-testid={`${item.key}-input`}
                    key={item.key}
                    value={item.key}
                    isSelected={values.otherMedicalNeeds?.includes(item.key)}
                    onChange={(value) => handleMedicalNeedsCheckboxOnchange(value.target.checked, item.key)}
                    isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
                <TextInput
                  id={nameOfMedicalDetailsForm.otherMedicalNote}
                  data-testid="medicalDetailsOtherMedicalNote-input"
                  label={labels[LabelKey.medicalDetails_medical_other]}
                  placeholder={labels[LabelKey.medicalDetails_medical_other]}
                  type="text"
                  name={nameOfMedicalDetailsForm.otherMedicalNote}
                  value={values.otherMedicalNote}
                  onChange={handleChange}
                  isDisabled={isSubmitting || isUpdateBlockedByBooking(updateBlockingReason)}
                  onBlur={handleBlur}
                  touched={touched.otherMedicalNote}
                />
                {isUpdateBlockedByBooking(updateBlockingReason) && (
                  <InformationBox
                    intent="warning"
                    title=""
                    content={labels[LabelKey.medicalDetails_cannot_update_now]}
                    iconName={"alert-circle-outlined"}
                    testid="medical-update-info"
                  />
                )}
              </div>
            </ErrorComponentWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicalDetails;
