import NavigationItem from "./NavigationItem/NavigationItem";
import QuotesNavigation from "./QuotesNavigation/QuotesNavigation";
import Header from "./Header/Header";
import Banner from "./Banner/Banner";
import Title from "./Title/Title";
import Text from "./Text/Text";
import ListBoxItem from "./ListBoxItem/ListBoxItem";
import Footer from "./Footer/Footer";
import ContentFragment from "./ContentFragment/ContentFragment";
import ListBox from "./ListBox/ListBox";
import Link from "./Link/Link";

export const storyblokBlocks = {
  banner: Banner,
  contentFragment: ContentFragment,
  footer: Footer,
  header: Header,
  link: Link,
  listBox: ListBox,
  listBoxItem: ListBoxItem,
  navigationItem: NavigationItem,
  quotesNavigation: QuotesNavigation,
  text: Text,
  title: Title,
};
