import React from "react";
import { Text } from "@ilc-technology/luik";
import { TextVariants } from "../../Common/LuikTypes";

export interface TwoValuesCellOptionsProps {
  leftTextClassName?: string;
  rightTextClassName?: string;
}
interface TitleValueCellProps {
  leftTextValue?: string;
  leftTextVariant?: TextVariants;
  rightTextValue?: string;
  rightTextVariant?: TextVariants;
  testId?: string;
  leftValueChildren?: React.ReactNode;
  rightValueChildren?: React.ReactNode;
  className?: string;
  options?: TwoValuesCellOptionsProps;
}

const TitleValueCell: React.FC<TitleValueCellProps> = ({
  leftTextValue,
  leftTextVariant,
  rightTextValue,
  rightTextVariant,
  leftValueChildren,
  rightValueChildren,
  className = "flex w-full justify-between gap-1",
  testId = "text",
  options,
}) => {
  return (
    <div className={className}>
      {leftValueChildren
        ? leftValueChildren
        : leftTextValue && (
            <Text
              variant={leftTextVariant ?? "label-lg"}
              className={options?.leftTextClassName}
              data-testid={`ll-${testId}`}
            >
              {leftTextValue}
            </Text>
          )}
      <div className="h-0 w-0" />
      {rightValueChildren
        ? rightValueChildren
        : rightTextValue && (
            <Text
              variant={rightTextVariant ?? "label-lg-bold"}
              className={options?.rightTextClassName}
              data-testid={`rl-${testId}`}
            >
              {rightTextValue}
            </Text>
          )}
    </div>
  );
};

export default TitleValueCell;
