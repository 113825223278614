import React from "react";
import NavigationLink from "../../NavigationLink/NavigationLink";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase, SbUrl } from "../../../Common/StoryblokTypes";

export interface NavigationItemBlok extends BlokBase {
  title: string;
  url: SbUrl;
}

interface NavigationItemProps {
  blok: NavigationItemBlok;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ blok }) => {
  return (
    <ul {...storyblokEditable(blok)} key={blok._uid}>
      <NavigationLink url={blok?.url?.url} title={blok?.title} />
    </ul>
  );
};

export default NavigationItem;
