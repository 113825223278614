import React from "react";
import { RichText } from "@ilc-technology/luik";
import { ISbRichtext } from "@storyblok/react";
import Enrich, { SelectorData } from "../../../Common/services/TextEnricher";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface TextBlok extends BlokBase {
  content: ISbRichtext;
}

interface TextProps {
  blok: TextBlok;
}

/** This method will iterate over the entire object and use Enrich method on every found 'text' property.
 * This has to be done Since TextProps is a complex object and we want to interpolate text wherever it may hide
 */
const replaceTextProperty = (object: ISbRichtext, enricherContext: SelectorData) => {
  if (object.text) {
    object.text = Enrich(object.text, enricherContext);
  }
  object.content?.map((c) => replaceTextProperty(c, enricherContext));
};

const Text: React.FC<TextProps> = ({ blok }) => {
  const { quotes } = useQuoteContext();
  const enricherContext = {
    quotes: quotes.map((x) => x),
  };

  const content = blok.content;
  replaceTextProperty(blok.content, enricherContext);
  return (
    <>
      <div {...storyblokEditable(blok)} key={blok._uid}>
        <RichText richtext={content}></RichText>
      </div>
    </>
  );
};

export default Text;
