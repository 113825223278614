import { v4 as uuidv4 } from "uuid";
import { ResponseWithFlowId } from "../Types";
import { formattedEfFlowId } from "./ErrorService";

function wait(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

// retry times, uses array index for access, index 0 unused
const retryTimes = [1000, 2500, 5000, 10000, 15000];

export const efFlowIDHeaderName = "EF-Flow-ID";

/**
 * Simple retry wrapper on fetch, used for retrying a request multiple times in case of errors thrown
 * @param url address to call
 * @param fetchOptions fetch options, passed to fetch directly
 * @param responseCodesForRetry http response codes for which a retry should be performed; by default none are considered
 * @param fetchAttempt fetch attempt - used for retries
 * @returns Promise<Response> for underlying request
 */
export function fetchRetry(
  url: string,
  fetchOptions: RequestInit = {},
  responseCodesForRetry: number[] = [],
  fetchAttempt = -1
): Promise<ResponseWithFlowId> {
  let flowId: string;
  if (fetchOptions?.headers) {
    flowId = uuidv4();
    (fetchOptions.headers as Record<string, string>)["EF-Flow-ID"] = flowId;
    fetchOptions.keepalive = true;
  }

  function onError(err: Error) {
    ++fetchAttempt;
    if (fetchAttempt > retryTimes.length - 1) {
      err.message = `${err.message}. ${formattedEfFlowId(flowId)}`;
      throw err;
    }
    const delay = retryTimes[fetchAttempt];
    return wait(delay).then(() => fetchRetry(url, fetchOptions, responseCodesForRetry, fetchAttempt));
  }
  return fetch(url, fetchOptions)
    .then((response) => {
      if ((responseCodesForRetry ?? []).includes(response.status)) {
        return onError(new Error(`Retrying request after receiving response with status ${response.status}`));
      } else {
        const responseWithFlowId = response as ResponseWithFlowId;
        responseWithFlowId.efFlowId = flowId;
        return responseWithFlowId;
      }
    })
    .catch(onError);
}
