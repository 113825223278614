import { LineItem, LineItemCategory, Money, Quote, SalesItemSubType, SalesItemType } from "../Types";
import { ItemBrand, ProductItem } from "./Analytics";

export const generatePriceQuoteEventItems = (quoteData: Quote): ProductItem[] => {
  const allQuoteLineItems = quoteData.segments.flatMap((s) => s.lineItems);
  return allQuoteLineItems.map((qli) => generateItemFromLineItem(qli, quoteData));
};

export const generateItemFromLineItem = (
  item: LineItem,
  quote: Quote,
  listName?: string,
  sku?: string,
  price?: Money,
  quantity?: number
): ProductItem => {
  return {
    item_id: sku ? sku : item.sku,
    item_name: item?.englishDescription || item?.description,
    item_brand: ItemBrand.EF,
    item_category: quote.program,
    item_category2: quote.product,
    item_category3: LineItemCategory[item.category],
    item_category4: SalesItemType[item.type],
    item_category5: SalesItemSubType[item.subType],
    item_list_name: listName,
    price: price ? price.amount : item.price.amount / item.quantity,
    currency: price ? price.currency : item.price.currency,
    quantity: quantity ? quantity : item.quantity,
  };
};
