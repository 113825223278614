import React, { PropsWithChildren, useEffect, useState } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useDatasources, useStoryblokSlug } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useLocation } from "react-router-dom";

interface BasePageProps extends PropsWithChildren {
  withHeader?: boolean;
  withFooter?: boolean;
}

const BasePage: React.FC<BasePageProps> = ({ children, withHeader = true, withFooter = true }) => {
  const { activeLanguage } = useDatasources();
  const location = useLocation();
  const headerStory = withHeader && useStoryblokSlug("header", activeLanguage);
  const footerStory = withFooter && useStoryblokSlug("footer", activeLanguage);

  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const updateFooterHeight = () => {
      const footer = document.getElementById("footer");
      let newPadding = 0;
      if (footer) {
        newPadding = footer.offsetHeight;
      }
      setFooterHeight(newPadding);
    };

    // Call on mount to set initial height
    updateFooterHeight();

    // Add resize event listener
    window.addEventListener("resize", updateFooterHeight);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener("resize", updateFooterHeight);
  }, []);

  useEffect(() => {
    setFooterHeight(0); // Reset footer height when the location changes
  }, [location]);

  return (
    <div className="flex min-h-screen flex-col bg-chalk" style={{ paddingBottom: `${footerHeight}px` }}>
      {headerStory && headerStory.content && (
        <StoryblokComponent blok={headerStory.content} key={headerStory.content._uid} />
      )}
      {children}
      {footerStory && footerStory.content && (
        <StoryblokComponent blok={footerStory.content} key={footerStory.content._uid} />
      )}
    </div>
  );
};

export default BasePage;
