import React from "react";
import { Radio, Text } from "@ilc-technology/luik";

interface TBRadioProps {
  value: string;
  title?: string;
  description?: string;
  isDisabled: boolean;
  isSelected: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  testIdPrefix?: string;
}

const TBRadio: React.FC<TBRadioProps> = ({
  value,
  title,
  description,
  children,
  isSelected = false,
  isDisabled = false,
  onClick,
  testIdPrefix = "radio",
}) => {
  return (
    <div
      className={`a-rounded flex flex-col gap-1 self-stretch bg-white p-3 md:p-4 ${isSelected ? "border border-primary-accessible" : "border border-inactive"}`}
      onClick={onClick}
    >
      <div className="flex flex-col items-start gap-2">
        <div className="flex h-auto items-center justify-between gap-2 self-stretch">
          <div className="min-w-0 flex-1">
            <Radio value={value} className="py-1" isDisabled={isDisabled} aria-label={value}>
              <Text data-testid={`${testIdPrefix}-title`} variant="label-lg" className="break-words">
                {title}
              </Text>
            </Radio>
          </div>
          <Text variant="label-lg-bold" className="whitespace-nowrap" data-testid={`${testIdPrefix}-value`}>
            {description}
          </Text>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TBRadio;
