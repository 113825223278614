import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase, SbImage } from "../../../Common/StoryblokTypes";
import { TextBlok } from "../Text/Text";
import { TitleBlok } from "../Title/Title";
import { LinkBlok } from "../Link/Link";

type ContentBlok = TitleBlok | TextBlok | LinkBlok;

export interface BannerBlok extends BlokBase {
  backgroundImage: SbImage;
  content: Array<ContentBlok>;
}

interface BannerProps {
  blok: BannerBlok;
}

const Banner: React.FC<BannerProps> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-testid="banner">
      <div
        className="relative flex w-full flex-col bg-cover bg-center pb-content-offset pt-content-offset"
        style={{ backgroundImage: `url(${blok.backgroundImage.filename})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-85"></div>

        <div className="z-[1] inline-flex w-full flex-col items-center justify-center">
          <div className="a-gap inline-flex items-center justify-center self-stretch">
            <div className="a-gap mx-6 inline-flex shrink grow basis-0 flex-col items-start justify-center lg:mx-auto">
              <div className="a-gap-sm flex flex-col justify-center self-stretch md:items-center">
                <div className="flex flex-col justify-start gap-1 self-stretch text-white sm:gap-2 md:items-center">
                  {blok.content.map((blok: ContentBlok) => (
                    <StoryblokComponent blok={blok} key={blok._uid} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
