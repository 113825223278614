import React, { useEffect, useState } from "react";
import { AppConfig } from "./AppConfig";
import { apiPlugin, storyblokInit, useStoryblok, StoryblokComponent } from "@storyblok/react";
import { storyblokBlocks } from "./components/Storyblok/storyblokBlocks";
import { useLocation, useParams } from "react-router-dom";
import "@ilc-technology/luik/tailwindcss";
import "./App.scss";
import Fonts from "./components/Fonts/Fonts";

storyblokInit({
  accessToken: AppConfig.storyBlok.previewAccessToken,
  use: [apiPlugin],
  components: storyblokBlocks,
});

const PreviewApp: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const validationString = `${searchParams.get("_storyblok_tk[space_id]")}:${AppConfig.storyBlok.previewAccessToken}:${searchParams.get("_storyblok_tk[timestamp]")}`;
  const storyblokLanguage = searchParams.get("_storyblok_lang") ?? "default";
  const slug = params["*"]!.replace(new RegExp(`^${storyblokLanguage}/`), "");
  const story = useStoryblok(slug!, { version: "draft", language: storyblokLanguage });
  const [isEditor, setIsEditor] = useState<boolean>(false);

  useEffect(() => {
    const textEncoder = new TextEncoder();
    crypto.subtle.digest("SHA-1", textEncoder.encode(validationString)).then((arrayBuffer) => {
      const hash = Array.from(new Uint8Array(arrayBuffer))
        .map((value) => value.toString(16).padStart(2, "0"))
        .join("");
      if (hash === searchParams.get("_storyblok_tk[token]")) setIsEditor(true);
    });
  }, []);

  return (
    <>
      <Fonts />
      {isEditor && <StoryblokComponent blok={story.content} />}
    </>
  );
};

export default PreviewApp;
