import React from "react";
import { Text } from "@ilc-technology/luik";
import Surface from "../../Surface/Surface";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { formatDateRange, addDays, formatDate } from "../../../Common/Helpers/DateHelper";
import { FormatCurrency, FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import { Money, PaymentInfo, PaymentInfoStatus } from "../../../Common/Types";
import Enrich from "../../../Common/services/TextEnricher";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { getPaymentOrReservation } from "../../../Common/services/PaymentsService";
import TBCardStatus, { TBCardStatusAlignment, TBCardStatusPosition } from "../../BaseComponents/TBCardStatus";
import { ColorVariant } from "../../../Common/services/ColorService";
import TitleValueCell from "../../BaseComponents/TitleValueCell";

interface QuoteHeaderProps {
  destinationName: string;
  courseInfo?: string;
  startAt: string;
  endAt: string;
  duration: string;
  totalPrice: Money;
  payments?: Array<PaymentInfo>;
  lgMinimized?: boolean;
}

const QuoteHeader: React.FC<QuoteHeaderProps> = ({
  destinationName,
  courseInfo,
  startAt,
  endAt,
  duration,
  totalPrice,
  payments,
  lgMinimized = true,
}) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();

  const isQuotePaidOrReserved =
    payments && payments.find((x) => x.status === PaymentInfoStatus.Reserve || x.status === PaymentInfoStatus.Paid);
  const payment = getPaymentOrReservation(payments);

  const paymentMadeAt = payment?.madeAt
    ? payment?.status === PaymentInfoStatus.Paid
      ? formatDate(payment?.madeAt, language)
      : formatDate(addDays(new Date(payment?.madeAt), 1).toString(), language)
    : undefined;
  const enricherContext = isQuotePaidOrReserved
    ? {
        paymentStatus: payment?.status,
        paymentMadeAt: paymentMadeAt,
      }
    : {
        paymentStatus: "",
        paymentMadeAt: undefined,
      };
  const paymentInfoLabel = "paymentInfo_";
  const borderRadius = isQuotePaidOrReserved ? "bottom" : "all";
  const priceColumn = "border-t border-neutral-300 lg:border-t-0 lg:border-l lg:w-[275px] lg:items-end";

  return (
    <>
      <div
        className={`flex flex-col ${lgMinimized && "lg:mt-side-bar-top lg:sticky lg:top-6 lg:max-w-side-bar lg:self-start"}`}
      >
        {isQuotePaidOrReserved && (
          <TBCardStatus
            variant={
              isQuotePaidOrReserved && payment?.status == PaymentInfoStatus.Reserve
                ? ColorVariant.Yellow
                : ColorVariant.Green
            }
            alignment={TBCardStatusAlignment.Center}
            position={TBCardStatusPosition.OverCard}
            testId="payment-component"
          >
            <Text variant="label-sm-bold">{Enrich(labels[paymentInfoLabel + payment?.status], enricherContext)}</Text>
          </TBCardStatus>
        )}
        <Surface borderRadius={borderRadius}>
          <div className={"a-gap flex flex-col lg:max-h-[233px] lg:flex-row lg:justify-between"}>
            <div className="a-gap flex flex-col">
              <Text variant="special-eyebrow">
                {formatDateRange(startAt, endAt, language)} | {duration}
              </Text>
              <Text variant="heading-3-bold">{destinationName}</Text>
              {courseInfo && <Text variant="paragraph-small-body">{courseInfo}</Text>}
            </div>
            <div className={`gap-small flex flex-col justify-center pt-6 lg:gap-2 lg:pt-0 ${priceColumn}`}>
              <TitleValueCell
                leftTextValue={labels[LabelKey.total]}
                rightTextValue={FormattedMoney(totalPrice)}
                leftTextVariant="heading-5"
                rightTextVariant="heading-5-bold"
                className="flex flex-row items-center justify-between gap-2 lg:flex-col lg:items-end"
              />

              {isQuotePaidOrReserved && payment?.status === PaymentInfoStatus.Paid && (
                <div
                  className="flex flex-row items-center justify-between gap-4 lg:flex-col lg:items-end"
                  data-testid="paid-details-row"
                >
                  <Text className="label-lg lg:label-xl text-gray-45" variant="">
                    {labels[LabelKey.paymentsMade]}
                  </Text>
                  <Text className="label-lg-bold lg:label-xl-bold text-gray-45" variant="">
                    {FormatCurrency(payment.payment.amount, totalPrice.currency)}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </Surface>
      </div>
    </>
  );
};

export default QuoteHeader;
