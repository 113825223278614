import { DestinationMapping, LineItem, SalesItemSubType, SalesItemType } from "../Types";

export const LineItemIsExtraNight = (lineItem: LineItem): boolean => {
  if (
    lineItem.type === SalesItemType.Accommodation &&
    (lineItem.subType === SalesItemSubType.HostFamilyExtraNight ||
      lineItem.subType === SalesItemSubType.ResidenceExtraNight ||
      lineItem.subType === SalesItemSubType.HotelExtraNight)
  ) {
    return true;
  }
  return false;
};

export const AtlasFallbackDestination = (
  destination: string,
  atlasfallbackDestinationMappings: DestinationMapping
): string => atlasfallbackDestinationMappings[destination.toLowerCase()] || destination;
