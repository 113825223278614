import TopBar from "../../TopBar/TopBar";
import React, { useState, useEffect } from "react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import styles from "./Header.module.scss";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { BlokBase, SbImage } from "../../../Common/StoryblokTypes";
import { NavigationItemBlok } from "../NavigationItem/NavigationItem";
import { QuotesNavigationBlok } from "../QuotesNavigation/QuotesNavigation";
import { Link, useLocation } from "react-router-dom";
import { Text } from "@ilc-technology/luik";
import { StoryblokComponent } from "@storyblok/react";
import OpeningChevron from "../../BaseComponents/OpeningChevron";

type NavigationBlok = NavigationItemBlok | QuotesNavigationBlok;

export interface HeaderBlok extends BlokBase {
  logo: SbImage;
  title: string;
  navigation: Array<NavigationItemBlok | QuotesNavigationBlok>;
}

interface HeaderProps {
  blok: HeaderBlok;
}

const Header: React.FC<HeaderProps> = ({ blok }) => {
  const { session } = useSessionContext();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <ul className={styles.header} {...storyblokEditable(blok)} key={blok._uid} data-testid="header">
      <div>
        <TopBar logo={blok.logo} telephone={session?.opportunity?.telephone} />
        <div className={styles.topNav}>
          <div className={styles.topNavTitleContainer}>
            <Link to="/" className={styles.topNavTitle}>
              <Text variant="label-md" className={styles.topNavTitle}>
                {blok.title}
              </Text>
            </Link>
          </div>
          <div className={styles.topNavNavigationContainer}>
            {blok.navigation.map((blok: NavigationBlok) => (
              <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>
        <div className={styles.topNavMobile}>
          <div className={styles.topNavMobileTitleContainer}>
            <div className={styles.topNavMobileTitle}>
              <Link to="/" className={styles.topNavMobileTitle}>
                <Text variant="label-md" className={styles.topNavMobileTitle}>
                  {blok.title}
                </Text>
              </Link>
            </div>
          </div>
          <div className="flex-grow"></div>
          <OpeningChevron open={open} setOpen={setOpen} />
        </div>
        <div className={`${styles.topNavMobileNavigationModalContainer} ${open ? styles.open : ""}`}>
          {blok.navigation.map((blok: NavigationBlok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </div>
    </ul>
  );
};

export default Header;
