import React, { useState } from "react";
import { TextInput } from "@ilc-technology/luik";
import { Formik } from "formik";
import * as Yup from "yup";

export type CustomAmountValues = {
  amount: string;
};

interface CustomAmountProps {
  minAmount: number;
  maxAmount: number;
  placeHolderText: string;
  buttonTitle: string;
  valueOutOfRangeText: string;
  isDisabled: boolean;
  isVisible: boolean;
  isAmountConfirmed: boolean;
  testId?: string;
  onCustomAmountConfirmed?: (values: CustomAmountValues) => void;
}

const CustomAmount: React.FC<CustomAmountProps> = ({
  minAmount,
  maxAmount,
  placeHolderText,
  buttonTitle,
  valueOutOfRangeText,
  isDisabled = false,
  isVisible = false,
  isAmountConfirmed = false,
  testId = "custom-amount",
  onCustomAmountConfirmed,
}) => {
  const [initialValues] = useState<CustomAmountValues>({
    amount: "",
  });

  const handleSubmit = async (values: CustomAmountValues) => {
    if (onCustomAmountConfirmed) {
      onCustomAmountConfirmed(values);
    }
  };

  const getValidationSchema = (): unknown => {
    return Yup.object({
      amount: Yup.number().required().min(minAmount, valueOutOfRangeText).max(maxAmount, valueOutOfRangeText),
    });
  };

  return (
    <>
      {isVisible && !isAmountConfirmed && (
        <div className="flex w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema()}
            validateOnChange={true}
            validateOnMount={true}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit, dirty, values, handleBlur, setFieldValue, touched, errors, isValid }) => (
              <form
                onSubmit={handleSubmit}
                className="a-gap-sm flex w-full flex-col items-start sm:flex-row sm:justify-between"
                data-testid={testId}
              >
                <TextInput
                  autoFocus
                  data-testid="custom-amount-input"
                  aria-label="custom-amount-input"
                  className="w-full"
                  isRequired={true}
                  name="amount"
                  label={placeHolderText}
                  type="text"
                  inputMode="numeric"
                  value={values.amount}
                  onChange={(event) => {
                    let sanitized = event.target.value.replace(/[^0-9]/g, "");
                    if ((sanitized.match(/\./g) || []).length > 1) {
                      sanitized = sanitized.slice(0, -1);
                    }
                    setFieldValue("amount", sanitized);
                  }}
                  onBlur={handleBlur}
                  isDisabled={isDisabled}
                  isInvalid={errors.amount != null}
                  validationState={errors.amount ? "invalid" : "valid"}
                  errorMessage={valueOutOfRangeText}
                  touched={touched.amount}
                />
                <input
                  aria-label="confirm-amount-button"
                  data-testid="confirm-amount-button"
                  className="a-save-button w-full sm:w-auto"
                  type="submit"
                  disabled={isDisabled || isSubmitting || !dirty || !isValid}
                  value={buttonTitle}
                />
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default CustomAmount;
