import { CurrencyInfo } from "./Types";

export const AUTH_API_KEY: string = "TripBooker";
export const JWT_API_KEY: string = "Bearer";
export const SUCCESS: string = "Success";
//localhost:8080/?token=3Ct5%2bYjcC1q1H3OSPmDEEQklAzHOUoWZzTwTN2%2f5a88ToQm%2b2Asu0OVr%2bYULv9ixiq5i1
export const AUTH_TOKEN_REGEX = /[?&]token=([^&|$]*)/;
//http://localhost:8080/quote/9ac6dcbe-dccd-4147-9527-d7b1fa106b03
export const SALESFORCE_INTERGRATION_USER_MAIL = "sfintegration.user@ef.com";

export const AdyenResultCodes = {
  AuthenticationFinished: "AuthenticationFinished",
  AuthenticationNotRequired: "AuthenticationNotRequired",
  Authorised: "Authorised",
  Cancelled: "Cancelled",
  ChallengeShopper: "ChallengeShopper",
  Error: "Error",
  IdentifyShopper: "IdentifyShopper",
  Pending: "Pending",
  PresentToShopper: "PresentToShopper",
  Received: "Received",
  RedirectShopper: "RedirectShopper",
  Refused: "Refused",
};

export const TRUE: string = "true";

export const CURRENCY_MAPPING: Array<CurrencyInfo> = [
  {
    currencyCode: "AED",
    currencySymbol: "د.إ",
    displayPosition: "after",
  },
  {
    currencyCode: "ANG",
    currencySymbol: "NAf",
    displayPosition: "after",
  },
  {
    currencyCode: "ARA",
    currencySymbol: "₳",
    displayPosition: "after",
  },
  {
    currencyCode: "ARS",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "AUD",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "BND",
    currencySymbol: "B$",
    displayPosition: "after",
  },
  {
    currencyCode: "BRL",
    currencySymbol: "R$",
    displayPosition: "after",
  },
  {
    currencyCode: "CAD",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "CHF",
    currencySymbol: "Fr.",
    displayPosition: "after",
  },
  {
    currencyCode: "CLP",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "COP",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "CRC",
    currencySymbol: "₡",
    displayPosition: "after",
  },
  {
    currencyCode: "CZK",
    currencySymbol: "Kč",
    displayPosition: "after",
  },
  {
    currencyCode: "DKK",
    currencySymbol: "Kr.",
    displayPosition: "after",
  },
  {
    currencyCode: "DZD",
    currencySymbol: "DZD",
    displayPosition: "after",
  },
  {
    currencyCode: "ECS",
    currencySymbol: "S/.",
    displayPosition: "after",
  },
  {
    currencyCode: "ESP",
    currencySymbol: "Pt",
    displayPosition: "after",
  },
  {
    currencyCode: "EUR",
    currencySymbol: "€",
    displayPosition: "after",
  },
  {
    currencyCode: "GBP",
    currencySymbol: "£",
    displayPosition: "after",
  },
  {
    currencyCode: "HKD",
    currencySymbol: "HK$",
    displayPosition: "after",
  },
  {
    currencyCode: "HUF",
    currencySymbol: "Ft",
    displayPosition: "after",
  },
  {
    currencyCode: "IRR",
    currencySymbol: "﷼",
    displayPosition: "after",
  },
  {
    currencyCode: "JPY",
    currencySymbol: "¥",
    displayPosition: "after",
  },
  {
    currencyCode: "KRW",
    currencySymbol: "₩",
    displayPosition: "after",
  },
  {
    currencyCode: "KZT",
    currencySymbol: "₸",
    displayPosition: "after",
  },
  {
    currencyCode: "LYD",
    currencySymbol: "LD",
    displayPosition: "after",
  },
  {
    currencyCode: "MXP",
    currencySymbol: "Mex$",
    displayPosition: "after",
  },
  {
    currencyCode: "NLG",
    currencySymbol: "fl.",
    displayPosition: "after",
  },
  {
    currencyCode: "NOK",
    currencySymbol: "kr",
    displayPosition: "after",
  },
  {
    currencyCode: "NZD",
    currencySymbol: "$",
    displayPosition: "after",
  },
  {
    currencyCode: "PAB",
    currencySymbol: "B/.",
    displayPosition: "after",
  },
  {
    currencyCode: "PEN",
    currencySymbol: "S/",
    displayPosition: "after",
  },
  {
    currencyCode: "PLN",
    currencySymbol: "zł",
    displayPosition: "after",
  },
  {
    currencyCode: "RUB",
    currencySymbol: "₽",
    displayPosition: "after",
  },
  {
    currencyCode: "SAR",
    currencySymbol: "SR",
    displayPosition: "after",
  },
  {
    currencyCode: "SEK",
    currencySymbol: "kr",
    displayPosition: "after",
  },
  {
    currencyCode: "SGD",
    currencySymbol: "S$",
    displayPosition: "after",
  },
  {
    currencyCode: "TND",
    currencySymbol: "DT",
    displayPosition: "after",
  },
  {
    currencyCode: "TRY",
    currencySymbol: "₺",
    displayPosition: "after",
  },
  {
    currencyCode: "TWD",
    currencySymbol: "NT$",
    displayPosition: "after",
  },
  {
    currencyCode: "USD",
    currencySymbol: "$",
    displayPosition: "before",
  },
  {
    currencyCode: "UYP",
    currencySymbol: "$U",
    displayPosition: "after",
  },
  {
    currencyCode: "VEB",
    currencySymbol: "Bs",
    displayPosition: "after",
  },
  {
    currencyCode: "ZAR",
    currencySymbol: "R",
    displayPosition: "after",
  },
];
