import styles from "./QuotesNavigation.module.scss";
import React, { useEffect, useState } from "react";
import OpeningChevron from "../../BaseComponents/OpeningChevron";
import NavigationLink from "../../NavigationLink/NavigationLink";
import { useLocation } from "react-router-dom";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { SegmentType } from "../../../Common/Types";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { Text } from "@ilc-technology/luik";
import { BlokBase, LabelKey } from "../../../Common/StoryblokTypes";
import { truncateString } from "../../../Common/Helpers/TextHelper";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import Enrich from "../../../Common/services/TextEnricher";

export interface QuotesNavigationBlok extends BlokBase {
  Title: string;
}

interface QuotesNavigationProps {
  blok: QuotesNavigationBlok;
}

const QuotesNavigation: React.FC<QuotesNavigationProps> = ({ blok }) => {
  const [open, setOpen] = useState(false);
  const { labels } = useDatasources();
  const location = useLocation();
  const { quotes, quotesContentCache } = useQuoteContext();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <ul {...storyblokEditable(blok)} key={blok._uid}>
      {quotes.length > 0 && (
        <div className={styles.quotesNavigation} data-testid="quotes-navigation">
          <button onClick={() => setOpen(!open)}>
            <Text variant="label-md">{blok.Title}</Text>
          </button>
          <OpeningChevron open={open} setOpen={setOpen} />
        </div>
      )}
      <div
        className={`${styles.quotesNavigationDropdown} ${open ? styles.open : ""}`}
        data-testid="quotes-navigation-dropdown"
      >
        {quotes.map((q, index) => {
          const firstDestination = q.quoteData.segments?.find((s) => s.type === SegmentType.Course)!.destinationCode;
          const destinationLabel = truncateString(
            quotesContentCache.destinations.get(firstDestination)?.name ?? firstDestination,
            15
          );
          const totalDuration = q.quoteData.segments
            ?.filter((s) => s.type === SegmentType.Course)
            .reduce((acc, val) => acc + val.duration.value, 0);
          const weeksLabel =
            totalDuration === 1
              ? labels[LabelKey.durationWeek]
              : totalDuration > 4
                ? labels[LabelKey.duration5orMoreWeeks]
                : labels[LabelKey.duration2to4Weeks];
          const quoteUrl = `/quote/${q.quoteData.id}`;

          const displayValue = `${destinationLabel}, ${Enrich(weeksLabel, { numWeeks: totalDuration })}`;
          return <NavigationLink key={index} url={quoteUrl} title={displayValue} />;
        })}
      </div>
    </ul>
  );
};

export default QuotesNavigation;
