import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { ListBoxItemBlok } from "../ListBoxItem/ListBoxItem";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface ListBoxBlok extends BlokBase {
  listBoxItems: Array<ListBoxItemBlok>;
  verticalDirection: boolean;
}

interface ListBoxProps {
  blok: ListBoxBlok;
}

const ListBox: React.FC<ListBoxProps> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className={`${blok.verticalDirection ? "" : ""}`}>
        <div
          className={`inline-flex flex-col justify-between ${blok.verticalDirection ? "a-gap-sm" : "a-rounded a-gap w-full bg-white p-8 shadow md:flex-row"}`}
        >
          {blok.listBoxItems.map((blok: ListBoxItemBlok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListBox;
