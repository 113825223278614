import { Text } from "@ilc-technology/luik";
import Surface from "../Surface/Surface";
import React from "react";
import { forwardRef } from "react";

export interface TBSectionProps {
  title: string;
  children?: React.ReactNode;
}

const TBSection = forwardRef<HTMLDivElement, TBSectionProps>(({ title, children }, ref) => {
  return (
    <Surface>
      <div className="relative flex flex-col gap-6" ref={ref}>
        <Text variant="heading-5-bold">{title}</Text>
        {children}
      </div>
    </Surface>
  );
});

TBSection.displayName = "TBSection";

export default TBSection;
