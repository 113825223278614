import { ListItem } from "@ilc-technology/luik";
import React from "react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface ListBoxItemBlok extends BlokBase {
  isActive: boolean;
  text: string;
  title: string;
  hasBackground: boolean;
}

interface ListBoxItemProps {
  blok: ListBoxItemBlok;
}

const ListBoxItem: React.FC<ListBoxItemProps> = ({ blok }) => {
  return (
    <>
      {blok.isActive && (
        <div className="flex-1" {...storyblokEditable(blok)} key={blok._uid}>
          <ListItem
            asDiv
            iconName="arrow-right"
            iconSize="sm"
            hasBackground={blok.hasBackground}
            colorVariant="dark"
            titleVariant="paragraph-body-bold"
            title={blok.title}
            text={blok.text}
          />
        </div>
      )}
    </>
  );
};

export default ListBoxItem;
