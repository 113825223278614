import React from "react";
import { Button, Icon } from "@ilc-technology/luik";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { StringIndexableDictionary } from "../../../Common/Types";

export interface SortItem {
  name: string;
  translationKey: LabelKey;
}

export interface SortListHeaderProps {
  sortOrders: Array<SortItem>;
  labels: StringIndexableDictionary<string>;
  chosenSortKey: string;
  sortQuotes: (sortKey: string) => void;
}

const SortListHeader: React.FC<SortListHeaderProps> = ({ sortOrders, labels, chosenSortKey, sortQuotes }) => {
  if (sortOrders.length === 0) {
    return null;
  }
  return (
    <div className="flex items-center gap-2 overflow-auto p-1 px-2 pb-2" data-testid="sort-list-header">
      <Icon iconName="filter" className="text-gray-55" />
      {sortOrders.map((item, index) => {
        const buttonIntent = item.name === chosenSortKey ? "primary-black" : "secondary-black";
        const buttonIsDisabled = item.name === chosenSortKey;
        return (
          <div key={index} className="flex flex-grow">
            <Button
              aria-label="filter-button"
              className="a-chip"
              size="sm"
              as="h1"
              intent={buttonIntent}
              isDisabled={buttonIsDisabled}
              onPress={() => sortQuotes(item.name)}
            >
              {labels[item.translationKey]}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export { SortListHeader };
