import { PaymentStatus, Money } from "./Types";

export const generatePaymentSummaryUrl = (
  status: PaymentStatus,
  referenceId: string | undefined,
  paymentValue: Money | undefined,
  quoteId: string
) => {
  const searchParams = new URLSearchParams();
  if (status) {
    searchParams.append("status", status);
  }
  if (referenceId) {
    searchParams.append("referenceId", referenceId);
  }
  if (paymentValue?.amount) {
    searchParams.append("amount", paymentValue.amount.toString());
  }
  return `/paymentSummary/${quoteId}${searchParams.toString() && "?"}${searchParams.toString()}`;
};
