import React from "react";
import { IconButton } from "@ilc-technology/luik";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface LinkBlok extends BlokBase {
  title: string;
  icon: string;
  href: string;
}

interface LinkProps {
  blok: LinkBlok;
}

const Link: React.FC<LinkProps> = ({ blok }) => {
  const anchor = "#quoteList";

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-testid="sb-link">
      <div className="mt-2 flex flex-row align-middle">
        {blok.icon && (
          <div className="mr-2">
            <IconButton
              size="xs"
              iconName={blok.icon}
              intent="secondary-white"
              aria-label=""
              onPress={() => (location.hash = anchor)}
            />
          </div>
        )}
        <div className="overflow-hidden">
          <a href={anchor} className="align-middle underline underline-offset-4">
            {blok.title}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Link;
