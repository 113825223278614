import React from "react";
import { Footer as CefcomCondensedFooter } from "@ilc-technology/cefcom-react-footer";
import { IFooterData } from "@ilc-technology/cefcom-react-footer/build/src/types";

import "@ilc-technology/cefcom-react-footer/build/styles.css";
import { BlokBase } from "../../../Common/StoryblokTypes";

const footerData: IFooterData = {
  footer: {
    settings: {
      host: "",
      siteRootSlug: "",
      marketCode: "",
      marketNativeName: "",
      isRtl: true,
      isToggleMarket: false,
      languageCode: "",
      footerType: "condensed-footer",
      theme: "light",
      hideChangeCountryLink: true,
      hideLanguageToggle: true,
    },
    sections: [],
    legalLinks: [],
    copyrightLinks: [],
    serviceLabels: [],
    blocks: [],
    logo: {
      logoSrc: "",
      logoUrl: "",
      logoFallback: "",
      altLogoSrc: "",
      logoAltText: "",
      altText: "",
      footerLogo: "",
    },
    additionalCopyrightText: "",
  },
  toggleLinks: null,
  changeCountryLinks: [],
};

interface Link {
  anchorText: string;
  anchorUrl: string;
  anchorShortUrl: string;
  slug: string;
  cssClass: string;
}

interface Section {
  title: string;
  links: Array<Link>;
  isEnabled: boolean;
}

export interface FooterBlok extends BlokBase {
  sections: Array<Section>;
  legalLinks: Array<Link>;
  copyrightLinks: Array<Link>;
}

interface FooterProps {
  blok: FooterBlok;
}

const Footer: React.FC<FooterProps> = ({ blok }) => {
  const data = {
    ...footerData,
    footer: {
      ...footerData.footer,
      sections: blok.sections,
      legalLinks: blok.legalLinks,
      copyrightLinks: blok.copyrightLinks,
    },
  };

  return (
    <>
      <CefcomCondensedFooter data={data} />
    </>
  );
};

export default Footer;
