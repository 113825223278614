import QuoteCard from "../QuoteCard/QuoteCard";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Quote, SegmentType } from "../../../Common/Types";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { QuoteData, QuotesContent } from "../../../contexts/QuoteContext/QuoteContext";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import "../../../App.scss";
import { StandardTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { SortListHeader, SortItem } from "./SortListHeader";
import { generatePriceQuoteEventItems } from "../../../Common/services/AnalyticsMappings";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";

interface SortOrder {
  name: string;
  sort: (entries: Array<QuoteData>, quotesContent: QuotesContent) => Array<QuoteData>;
  translationKey: string;
}

const sortOrders: Array<SortOrder> = [
  {
    name: "createdAt",
    sort: (entries) =>
      [...entries].sort(
        (a, b) => new Date(b.quoteData.createdAt).getTime() - new Date(a.quoteData.createdAt).getTime()
      ),
    translationKey: LabelKey.sortBy_Created,
  },
  {
    name: "destinationName",
    sort: (entries, quotesContent) => {
      const getFirstDestination = (q: Quote) => q.segments?.find((s) => s.type === SegmentType.Course)!.destinationCode;
      return [...entries].sort((a, b) =>
        (quotesContent?.destinations?.get(getFirstDestination(a.quoteData))?.name ?? getFirstDestination(a.quoteData)) >
        (quotesContent?.destinations?.get(getFirstDestination(b.quoteData))?.name ?? getFirstDestination(b.quoteData))
          ? 1
          : -1
      );
    },
    translationKey: LabelKey.sortBy_Destination,
  },
  {
    name: "duration",
    sort: (entries) => {
      const getDuration = (q: Quote) =>
        q.segments?.filter((s) => s.type === SegmentType.Course).reduce((acc, val) => acc + val.duration.value, 0);
      return [...entries].sort(
        (a, b) =>
          moment.duration(getDuration(a.quoteData)).asWeeks() - moment.duration(getDuration(b.quoteData)).asWeeks()
      );
    },
    translationKey: LabelKey.sortBy_Duration,
  },
  {
    name: "weeklyPrice",
    sort: (entries) => {
      const getWeeklyPrice = (q: Quote) =>
        q.prices.totalPrice.amount /
        moment.duration(q.segments?.reduce((acc, val) => acc + val.duration.value, 0)).asWeeks();
      return [...entries].sort((a, b) => getWeeklyPrice(a.quoteData) - getWeeklyPrice(b.quoteData));
    },
    translationKey: LabelKey.sortBy_WeeklyPrice,
  },
  {
    name: "totalPrice",
    sort: (entries) =>
      [...entries].sort((a, b) => a.quoteData.prices.totalPrice.amount - b.quoteData.prices.totalPrice.amount),
    translationKey: LabelKey.sortBy_TotalPrice,
  },
];

interface QuoteListBoxProps {
  quotes: Array<QuoteData>;
  quotesContent: QuotesContent;
}

const QuoteListBox: React.FC<QuoteListBoxProps> = ({ quotes, quotesContent }) => {
  const { labels } = useDatasources();
  const [quotesSortKey, setQuotesSortKey] = useState("createdAt");
  const [sortedQuotesData, setSortedQuotesData] = useState<Array<QuoteData>>([] as Array<QuoteData>);
  const { session } = useSessionContext();

  const sortItems: Array<SortItem> = sortOrders.map((order) => ({
    name: order.name,
    translationKey: order.translationKey as LabelKey,
  }));

  useEffect(() => {
    sortQuotes(quotesSortKey);
  }, [quotes]);

  useEffect(() => {
    if (quotes.length > 0) {
      trackEvent(session.opportunity.id, StandardTrackingEvent.ViewItemList, {
        item_list_id: quotes[0].quoteData.opportunityUuid,
        item_list_name: "Price Quotes",
        items: quotes.flatMap((quote) => generatePriceQuoteEventItems(quote.quoteData)),
      });
    }
  }, [quotes.length]);

  const sortQuotes = (sortKey: string) => {
    setQuotesSortKey(sortKey);

    const sortFunction = sortOrders.find((so) => so.name === sortKey)?.sort;
    setSortedQuotesData(sortFunction?.(quotes, quotesContent) ?? quotes);
  };

  return (
    <div className="pt-6">
      {sortItems.length > 2 && (
        <SortListHeader sortOrders={sortItems} labels={labels} chosenSortKey={quotesSortKey} sortQuotes={sortQuotes} />
      )}
      <div data-testid="quote-cards" className="a-gap flex flex-col">
        {sortedQuotesData.map((q) => {
          return (
            <Fragment key={q.quoteData.id}>
              <QuoteCard quote={q} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default QuoteListBox;
