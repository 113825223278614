import React from "react";

interface RoundedCardProps {
  children?: React.ReactNode;
  testId?: string;
}

const RoundedCard: React.FC<RoundedCardProps> = ({ children, testId = "rounded-card" }) => {
  return (
    <div className="mx-0 rounded-xl bg-neutral-50 px-3 py-3 md:rounded-3xl md:px-6 md:py-6" data-testid={testId}>
      {children}
    </div>
  );
};

export default RoundedCard;
