import React, { useEffect } from "react";
import { setTrackingDimensions, initializeAnalytics, trackPageView } from "../../Common/services/Analytics";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { useLocation } from "react-router-dom";
import { convertToErrorDetails, logError } from "../../Common/services/ErrorService";
import { ErrorCode } from "../../Common/Types";

const Analytics: React.FC = () => {
  const { session } = useSessionContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (session.opportunity?.id) {
      setTrackingDimensions({
        account_id: session.user?.accountId,
      });
    } else {
      logError(
        convertToErrorDetails(
          `Session object is missing some values in the opportunity section(cannot find id): ${JSON.stringify(session.opportunity)}`,
          ErrorCode.IdentityPropertiesMisconfigured
        )
      );
    }
  }, [session.opportunity?.id]);

  useEffect(() => {
    if (session.user?.accountId) {
      initializeAnalytics(session.user.accountId);
    } else {
      logError(
        convertToErrorDetails(
          `Session object is missing some values in the user section(cannot find accountId): ${JSON.stringify(session.user)}`,
          ErrorCode.IdentityPropertiesMisconfigured
        )
      );
    }
  }, [session.user?.accountId]);

  useEffect(() => {
    trackPageView(session.user?.accountId, pathname);
  }, [pathname]);

  return <></>;
};

export default Analytics;
