import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase } from "../../../Common/StoryblokTypes";
import { BannerBlok } from "../Banner/Banner";
import { FooterBlok } from "../Footer/Footer";
import { HeaderBlok } from "../Header/Header";
import { ListBoxItemBlok } from "../ListBoxItem/ListBoxItem";
import { NavigationItemBlok } from "../NavigationItem/NavigationItem";
import { QuotesNavigationBlok } from "../QuotesNavigation/QuotesNavigation";
import { ListBoxBlok } from "../ListBox/ListBox";
import { TextBlok } from "../Text/Text";
import { TitleBlok } from "../Title/Title";
import { LinkBlok } from "../Link/Link";

type ContentFragmentBlocksBlok =
  | BannerBlok
  | FooterBlok
  | HeaderBlok
  | LinkBlok
  | ListBoxItemBlok
  | ListBoxBlok
  | NavigationItemBlok
  | QuotesNavigationBlok
  | TextBlok
  | TitleBlok;

interface ContentFragmentBlok extends BlokBase {
  blocks: Array<ContentFragmentBlocksBlok>;
}

interface ContentFragmentProps {
  blok: ContentFragmentBlok;
}

const ContentFragment: React.FC<ContentFragmentProps> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      {blok.blocks.map((blok: ContentFragmentBlocksBlok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
};

export default ContentFragment;
