import { StringIndexableDictionary } from "../Types";
import { fetchRetry } from "./FetchRetry";
import { returnJsonOrThrowError } from "./ErrorService";
import { AppConfig } from "../../AppConfig";

export type CentralApiPdfResponseItem = {
  programCode: string;
  filePath: string;
  publicationDate: string;
  version: string;
};

export type TermsAndCondition = {
  filePath: string;
  version: string;
};

export const FetchTermsAndConditions = (
  languageCode: string
): Promise<StringIndexableDictionary<TermsAndCondition>> => {
  return fetchRetry(` ${AppConfig.api.centralApi}/common/program/v2/getpdf/${languageCode}/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => returnJsonOrThrowError<Array<CentralApiPdfResponseItem>>(response))
    .then((data: Array<CentralApiPdfResponseItem>) => {
      const centralApiData = Object.fromEntries(
        data.map((x) => [x.programCode, { filePath: x.filePath, version: x.version }])
      );
      // mapping central-api programs to our programs
      return {
        ILC: centralApiData["ILSY"],
        ILS: centralApiData["ILS"],
        AY: centralApiData["AYA"],
        MLY: centralApiData["AYA"],
        EXC: centralApiData["ILS"],
      };
    });
};
