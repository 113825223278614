import { PaymentInfo, PaymentInfoStatus, Quote } from "../Types";

export function getPaymentOrReservation(payments?: Array<PaymentInfo> | null): PaymentInfo | undefined {
  if (!payments) {
    return undefined;
  }
  const sortedPayments = [...payments].sort((a, b) => new Date(b.madeAt).getTime() - new Date(a.madeAt).getTime());
  return sortedPayments.find((x) => x.status === PaymentInfoStatus.Paid) ?? sortedPayments[0];
}

export function getQuotePaymentStatus(quote?: Quote): PaymentInfoStatus | undefined {
  const paymentInfo = getPaymentOrReservation(quote?.payments);
  if (!paymentInfo) {
    return undefined;
  }
  return paymentInfo.status;
}
