import { LabelKey } from "./StoryblokTypes";
import { LineItem, SalesItemType } from "./Types";

export type LineItemGroup = {
  [key: string]: {
    displayOrder: number;
    lineItems: Array<LineItem>;
  };
};

type DisplayCategories = {
  [key: string]: {
    displayOrder: number;
    salesItemTypeCodes: Array<number>;
  };
};

const salesItemDisplayCategories: DisplayCategories = {
  [LabelKey.headerCourses]: {
    salesItemTypeCodes: [SalesItemType.Course],
    displayOrder: 1,
  },
  [LabelKey.headerAccommodation]: {
    salesItemTypeCodes: [SalesItemType.Accommodation],
    displayOrder: 2,
  },
  [LabelKey.headerInsurance]: {
    salesItemTypeCodes: [SalesItemType.Insurance],
    displayOrder: 3,
  },
  [LabelKey.headerTransfer]: {
    salesItemTypeCodes: [SalesItemType.Travel],
    displayOrder: 4,
  },
  [LabelKey.headerOther]: {
    salesItemTypeCodes: [
      SalesItemType.None,
      SalesItemType.Tax,
      SalesItemType.CourseSpecific,
      SalesItemType.SpecialArticle,
      SalesItemType.SystemArticle,
      SalesItemType.EnrollmentFee,
      SalesItemType.Item,
      SalesItemType.Vtm,
      SalesItemType.Other,
      SalesItemType.Deposit,
      SalesItemType.Course,
    ],
    displayOrder: 5,
  },
};

const groupLineItems = (lineItems: Array<LineItem>) => {
  const groupedLineItems: LineItemGroup = {};

  for (const item of lineItems) {
    const labelKey =
      Object.keys(salesItemDisplayCategories).find((key) =>
        salesItemDisplayCategories[key].salesItemTypeCodes.includes(item.type)
      ) ?? LabelKey.headerOther;
    if (!groupedLineItems[labelKey])
      groupedLineItems[labelKey] = {
        displayOrder: salesItemDisplayCategories[labelKey].displayOrder,
        lineItems: [],
      };
    groupedLineItems[labelKey].lineItems.push(item);
  }

  const sortedGroupedLineItems: LineItemGroup = [...Object.entries(groupedLineItems)]
    .sort(([, a], [, b]) => a.displayOrder - b.displayOrder)
    .reduce((r, [key, value]) => ({ ...r, [key]: value }), {});
  return sortedGroupedLineItems;
};

export { groupLineItems };
