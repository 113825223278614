import {
  Guid,
  LineItemCategory,
  PaymentInfo,
  PaymentInfoStatus,
  Quote,
  QuotePaymentStatus,
  SalesItemType,
  Segment,
  SegmentType,
} from "../Types";
import { DestinationContent } from "../../contexts/QuoteContext/QuoteContext";

export type QuoteInformation = {
  quoteInfo: Quote;
  courseSegments: Segment[];
  startAt: string;
  endAt: string;
  isMultiQuote: boolean;
  courseInfo: string | undefined;
  totalDuration: number;
};

const getPaymentStatus = (payments?: Array<PaymentInfo>): QuotePaymentStatus => {
  if (payments?.some((payment) => payment.status === PaymentInfoStatus.Paid)) return QuotePaymentStatus.Paid;
  if (payments?.some((payment) => payment.status === PaymentInfoStatus.Reserve)) return QuotePaymentStatus.Reserved;
  return QuotePaymentStatus.Unreserved;
};

const getQuoteWithBundleItemsBundled = (rawQuote: Quote): Quote => {
  if (!rawQuote.segments) {
    return rawQuote;
  }
  // make a copy to not modify the object passed as an argument
  const quote: Quote = JSON.parse(JSON.stringify(rawQuote));
  quote.segments.forEach((segment) => {
    if (!segment.lineItems) {
      return;
    }
    const bundledLineItems = segment.lineItems.filter((lineItem) => lineItem.isBundled);
    const bundleParentIds = new Set<Guid>();
    bundledLineItems.forEach((element) => {
      bundleParentIds.add(element.bundleId);
    });

    bundleParentIds.forEach((bundleId) => {
      const bundleItems = bundledLineItems.filter((item) => item.bundleId === bundleId);
      const parentItem = bundledLineItems.find((item) => item.bundleId === item.id)!;
      if (parentItem === undefined) {
        console.error(
          `Quote with id ${rawQuote.id} contains bundle items but the parent for the bundle item with id ${bundleId} cannot be found!`
        );
      }

      bundleItems.forEach((item) => {
        if (item.id !== parentItem.id) {
          parentItem.totalPrice.amount += item.totalPrice.amount;
          if (parentItem.category === LineItemCategory.Course) {
            segment.course.price.amount += item.totalPrice.amount;
            segment.lineItems = segment.lineItems.filter((lineItem) => lineItem.id !== item.id);
          }
        }
      });
    });
  });
  return quote;
};

//Remove all the bundled lineItems and add their price to the price of the course
export function processBundleItemsAndPaymentStatus(rawQuote: Quote): Quote {
  const quote = getQuoteWithBundleItemsBundled(rawQuote);
  quote.status = getPaymentStatus(quote.payments);
  return quote;
}

export function getQuoteInformation(quote: Quote): QuoteInformation {
  const courseSegments = quote.segments?.filter((segment) => segment.type === SegmentType.Course) ?? [];
  const startAt = quote.segments[0]?.startAt || ""; //The component where this is used won't render if quoteData is undefined
  const endAt = quote.segments[quote.segments?.length - 1]?.endAt || ""; // so we can have a default of "" to avoid type errors
  const isMultiQuote = (quote.segments?.length || 0) > 1;
  const courseInfo = isMultiQuote
    ? ""
    : courseSegments?.[0]?.lineItems.find((lineItem) => lineItem.type == SalesItemType.Course)?.description;
  const totalDuration = courseSegments ? courseSegments.reduce((acc, val) => acc + val.duration.value, 0) : 0;

  return {
    quoteInfo: quote,
    courseSegments,
    startAt,
    endAt,
    isMultiQuote,
    courseInfo,
    totalDuration,
  };
}

export function getDestinationName(
  segments: Segment[],
  destinations: Map<string, DestinationContent>,
  separator: string = " - "
): string {
  if (!segments || !destinations) return "";

  return segments
    .map((segment) => destinations.get(segment.destinationCode)?.name ?? segment.destinationCode)
    .join(separator);
}
