import React from "react";
import { Icon, IconProps, Text } from "@ilc-technology/luik";

interface WhatsIncludedTitleProps {
  icon: IconProps;
  title: string;
}

const WhatsIncludedTitle: React.FC<WhatsIncludedTitleProps> = ({ icon, title }) => {
  return (
    <>
      <div className="flex flex-row gap-3">
        {icon && <Icon size="base" iconName={icon.iconName} color={icon.color} />}
        <Text variant="paragraph-body-bold">{title}</Text>
      </div>
    </>
  );
};

export default WhatsIncludedTitle;
