import React, { useState } from "react";
import { Accordion, Button, Text } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import UpgradeDurationModal from "./UpgradeDurationModal";
import Enrich from "../../Common/services/TextEnricher";
import { QuoteDraft } from "../../contexts/QuoteDetailsContext";
import { Quote } from "../../Common/Types";

interface UpgradeDurationProps {
  isSaveInProgress: boolean;
  quote: Quote;
  quoteDraft: QuoteDraft;
}

const UpgradeDuration: React.FC<UpgradeDurationProps> = ({ isSaveInProgress, quote, quoteDraft }) => {
  const { labels } = useDatasources();
  const [isModalOpen, setModalOpen] = useState(false);
  const originalQuoteSegment = quote.segments[0];
  const quoteDraftSegment = quoteDraft.quoteDraft.quoteData.segments[0];
  const isDurationChanged =
    quoteDraftSegment &&
    originalQuoteSegment &&
    originalQuoteSegment?.duration.value != quoteDraftSegment.duration.value;
  const durationDifference = isDurationChanged
    ? quoteDraftSegment?.duration.value - originalQuoteSegment.duration.value
    : 0;
  const weeksLabel = durationDifference === 1 ? labels[LabelKey.durationWeek] : labels[LabelKey.durationWeeks];

  const handleAdd = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const bgColor = isDurationChanged ? "bg-neutral-50 shadow-md pl-2 pt-2 pb-2" : "";

  return (
    <>
      <Accordion
        className="upgrade"
        startsOpen
        intent="black"
        data={[
          {
            title: labels[LabelKey.addWeeks],
            children: (
              <div className="a-gap-sm flex flex-col" data-testid="upgrade-duration-component">
                <Text variant="paragraph-body">{labels[LabelKey.durationUpgradeDescription]}</Text>
                <div className={`a-rounded flex flex-row items-center justify-between ${bgColor}`}>
                  {isDurationChanged ? (
                    <div className="flex flex-col">
                      <Text variant="label-md-bold"> + {Enrich(weeksLabel, { numWeeks: durationDifference })}</Text>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="flex justify-end">
                    <Button
                      data-testid={isDurationChanged ? "change-duration-button" : "add-duration-button"}
                      size="base"
                      intent={isDurationChanged ? "secondary-black" : "primary-black"}
                      iconName="arrow-right"
                      iconPosition="end"
                      onPress={handleAdd}
                      isDisabled={isSaveInProgress}
                    >
                      {isDurationChanged ? labels[LabelKey.change] : labels[LabelKey.addButton]}
                    </Button>
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
      {isModalOpen && (
        <UpgradeDurationModal
          isModalOpen={isModalOpen}
          editableQuoteDraft={quoteDraft}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default UpgradeDuration;
