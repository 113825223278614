import { AppConfig } from "../AppConfig";
import { convertToErrorDetails, logError } from "../Common/services/ErrorService";
import { ErrorCode, ErrorDetails } from "../Common/Types";
import {
  Api,
  CreateRelatedContactRequest,
  Customer,
  HttpResponse,
  MedicalsPutRequest,
  MedicalsResponse,
  RelatedContacts,
  RequestCustomerChange,
  UpdateRelatedContactRequest,
  ValidationProblemResponse,
} from "./generatedCustomerServiceApiClient";
import fetch_retry, { RequestInitWithRetry } from "fetch-retry";
import { v4 as uuidv4 } from "uuid";

const fetchWithRetry = fetch_retry(window?.fetch);

const AUTHORIZATION_SCHEME = "Bearer";

export type Allergies =
  | "PETS_ALLERGY"
  | "POLLEN_ALLERGY"
  | "NUTS_ALLERGY"
  | "INSECT_ALLERGY"
  | "DUST_ALLERGY"
  | "PENICILLIN_ALLERGY";
export type DietaryNeeds = "HALAL" | "GLUTEN_FREE" | "VEGAN" | "VEGETARIAN" | "KOSHER" | "LACTOSE_FREE";
export type Disabilities = "USES_A_WHEELCHAIR" | "VISUALLY_IMPAIRED" | "DEAF";
export type MedicalNeeds = "EPILEPTIC" | "ASTHMATIC" | "DIABETIC";

const medicalMap: { [key: string]: Allergies | DietaryNeeds | Disabilities | MedicalNeeds } = {
  Dust: "DUST_ALLERGY",
  Insects: "INSECT_ALLERGY",
  Nuts: "NUTS_ALLERGY",
  Pets: "PETS_ALLERGY",
  Pollen: "POLLEN_ALLERGY",
  Vegan: "VEGAN",
  Halal: "HALAL",
  "Gluten free": "GLUTEN_FREE",
  Kosher: "KOSHER",
  Vegetarian: "VEGETARIAN",
  "Lactose free": "LACTOSE_FREE",
  Asthmatic: "ASTHMATIC",
  Epileptic: "EPILEPTIC",
  Diabetic: "DIABETIC",
  Deaf: "DEAF",
  "Visually impaired": "VISUALLY_IMPAIRED",
  "Uses a Wheelchair": "USES_A_WHEELCHAIR",
  Penicillin: "PENICILLIN_ALLERGY",
};

export type MedicalsUpsertResponse = {
  isSuccessful: boolean;
  error?: ErrorDetails;
};

export type MedicalDetailsResponse = MedicalsUpdateBlockingResponse & {
  allergies: Allergies[];
  dietaryNeeds: DietaryNeeds[];
  disabilities: Disabilities[];
  medicalNeeds: MedicalNeeds[];
  allergyNotes: string;
  dietaryNotes: string;
  disabilityNotes: string;
  medicalNotes: string;
  medications: string;
};

export type MedicalsUpdateBlockingResponse = {
  updateBlockingReason: "BookingStartingTooSoon" | null;
};

export type MedicalsResponseWithBooking = (MedicalsResponse & MedicalsUpdateBlockingResponse) | unknown;

export class CustomerServiceApi {
  private readonly customerServiceApiClient;

  public constructor() {
    const token: string | null = sessionStorage.getItem(AppConfig.sessionStorageKeys.jwtToken);
    if (token == null) {
      throw new ErrorDetails({
        code: ErrorCode.AuthenticationFailed,
        details: { additionalDetails: "Authentication token is missing. Please log in." },
      });
    }
    this.customerServiceApiClient = new Api({
      baseUrl: `${AppConfig.api.customerServiceApi}`,
      securityWorker: () => {
        return {
          headers: {
            Authorization: `${AUTHORIZATION_SCHEME} ${token}`,
          },
        };
      },
      customFetch: (input: string | URL | globalThis.Request, init?: RequestInit) => {
        const withRetryParams: RequestInitWithRetry<typeof global.fetch> = init ?? {};
        withRetryParams.retries = 3;
        withRetryParams.retryOn = [400, 429, 500, 503];
        withRetryParams.retryDelay = function (attempt) {
          return Math.pow(2, attempt) * 1000;
        };
        return fetchWithRetry(input, withRetryParams);
      },
    });
  }

  public async getMedicals(accountId: string): Promise<MedicalDetailsResponse> {
    try {
      const response = await this.customerServiceApiClient.customers.medicalsDetail(accountId, {
        headers: {
          "EF-Flow-Id": uuidv4(),
        },
      });
      const allergies = response.data.items?.find((item) => item.category === "allergies");
      const dietaryNeeds = response.data.items?.find((item) => item.category === "dietary-needs");
      const disabilities = response.data.items?.find((item) => item.category === "disabilities");
      const medicals = response.data.items?.find((item) => item.category === "others");

      return {
        updateBlockingReason: (response.data as MedicalsUpdateBlockingResponse)?.updateBlockingReason,
        allergies: allergies?.types?.filter((i) => medicalMap[i]).map((item) => medicalMap[item]) ?? [],
        allergyNotes: allergies?.note,
        dietaryNeeds: dietaryNeeds?.types?.filter((i) => medicalMap[i]).map((item) => medicalMap[item]) ?? [],
        dietaryNotes: dietaryNeeds?.note,
        disabilities: disabilities?.types?.filter((i) => medicalMap[i]).map((item) => medicalMap[item]) ?? [],
        disabilityNotes: disabilities?.note,
        medicalNeeds: medicals?.types?.filter((i) => medicalMap[i]).map((item) => medicalMap[item]) ?? [],
        medicalNotes: medicals?.note,
        medications: medicals?.carriesMedication,
      } as MedicalDetailsResponse;
    } catch (error: unknown) {
      const errorDetails = convertToErrorDetails(error, ErrorCode.MedicalDetailsUpdateFailed);
      logError(errorDetails);
      throw errorDetails;
    }
  }

  private async wrapWithErrorHandling<ModelType, Type extends { data: ModelType }>(
    errorType: ErrorCode,
    call: () => Promise<Type>
  ) {
    try {
      return (await call()).data;
    } catch (error: unknown) {
      const errorDetails = convertToErrorDetails(error, errorType);
      logError(errorDetails);
      throw errorDetails;
    }
  }

  private generateStandardHeaders() {
    return {
      headers: {
        "EF-Flow-Id": uuidv4(),
      },
    };
  }

  public async upsertMedicals(accountId: string, body: MedicalsPutRequest): Promise<void> {
    return await this.wrapWithErrorHandling<void, HttpResponse<void, void | ValidationProblemResponse>>(
      ErrorCode.MedicalDetailsUpdateFailed,
      () => this.customerServiceApiClient.customers.medicalsUpdate(accountId, body, this.generateStandardHeaders())
    );
  }

  public async getCustomer(id: string): Promise<Customer> {
    return await this.wrapWithErrorHandling<Customer, HttpResponse<Customer, void | ValidationProblemResponse>>(
      ErrorCode.CustomerDetailsFetchFailed,
      () => this.customerServiceApiClient.customers.customersDetail(id, this.generateStandardHeaders())
    );
  }

  public async updateCustomer(id: string, changes: RequestCustomerChange): Promise<void> {
    return await this.wrapWithErrorHandling<void, HttpResponse<void, void | ValidationProblemResponse>>(
      ErrorCode.CustomerDetailsFetchFailed,
      () => this.customerServiceApiClient.customers.customersPartialUpdate(id, changes, this.generateStandardHeaders())
    );
  }

  public async getRelatedContacts(id: string): Promise<RelatedContacts> {
    return await this.wrapWithErrorHandling<
      RelatedContacts,
      HttpResponse<RelatedContacts, void | ValidationProblemResponse>
    >(ErrorCode.RelatedContactFetchFailed, () =>
      this.customerServiceApiClient.customers.relatedContactsDetail(id, this.generateStandardHeaders())
    );
  }

  public async addRelatedContact(id: string, contact: CreateRelatedContactRequest): Promise<void> {
    return await this.wrapWithErrorHandling<void, HttpResponse<void, void | ValidationProblemResponse>>(
      ErrorCode.RelatedContactCreationFailed,
      () => this.customerServiceApiClient.customers.relatedContactsCreate(id, contact, this.generateStandardHeaders())
    );
  }

  public async updateRelatedContact(
    id: string,
    relatedContactId: string,
    data: UpdateRelatedContactRequest
  ): Promise<void> {
    return await this.wrapWithErrorHandling<void, HttpResponse<void, void | ValidationProblemResponse>>(
      ErrorCode.CustomerDetailsFetchFailed,
      () =>
        this.customerServiceApiClient.customers.relatedContactsPartialUpdate(
          id,
          relatedContactId,
          data,
          this.generateStandardHeaders()
        )
    );
  }
}
