import { Icon } from "@ilc-technology/luik";
import React from "react";

interface OpeningChevronProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const OpeningChevron: React.FC<OpeningChevronProps> = ({ open, setOpen }) => {
  return (
    <div
      className={`mx-2 my-2 flex h-4 w-4 transform items-end justify-center transition-transform duration-300 ease-in-out ${open ? "scale-y-[-1]" : ""}`}
    >
      <button onClick={() => setOpen(!open)}>
        <Icon className="h-4 w-4 flex-shrink-0" iconName="chevron-down" size="sm" />
      </button>
    </div>
  );
};

export default OpeningChevron;
