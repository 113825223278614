import React from "react";
import { forwardRef } from "react";
import { ColorVariant, getBgColorForVariant, getTextColorForVariant } from "../../Common/services/ColorService";

export enum TBCardStatusAlignment {
  Center,
  Spread,
}

export enum TBCardStatusPosition {
  OverCard,
  UnderCard,
}

export interface TBCardStatusProps {
  position: TBCardStatusPosition;
  variant?: ColorVariant;
  alignment?: TBCardStatusAlignment;
  size?: "lg" | "sm";
  children?: React.ReactNode;
  testId?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

// Component that can be shown on the top or bottom of the card to display status (quote, payment etc.)
const TBCardStatus = forwardRef<HTMLDivElement, TBCardStatusProps>(
  (
    {
      children,
      position,
      size = "lg",
      variant = ColorVariant.Black,
      alignment = TBCardStatusAlignment.Spread,
      testId = "card-status",
      onClick,
    },
    ref
  ) => {
    const alignmentStyle = alignment == TBCardStatusAlignment.Spread ? "w-full" : "";
    const radius = position == TBCardStatusPosition.UnderCard ? "rounded-b-lg" : "rounded-t-lg";
    return (
      <div
        data-testid={testId}
        className={`shadow-card ${size == "lg" ? "h-card-status" : "h-card-status-sm"} z-[2] flex ${alignmentStyle} items-center justify-center ${radius} a-card-px ${getTextColorForVariant(variant)} ${getBgColorForVariant(variant)} flex-nowrap text-nowrap ${onClick ? "cursor-pointer" : "cursor-default"}`}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);

TBCardStatus.displayName = "TBCardStatus";

export default TBCardStatus;
