import { Text } from "@ilc-technology/luik";
import React from "react";

interface AdditionalInfoProps {
  additionalInfo?: string;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ additionalInfo }) => {
  if (!additionalInfo || additionalInfo.length === 0) {
    return null;
  }
  return (
    <div className="justify-around">
      <div className="mb-4" />
      <div className="text-[#bfbfbf]">
        <hr />
        <Text as="p" variant="paragraph-footnote" data-testid="error-additional-info">
          {additionalInfo}
        </Text>
      </div>
    </div>
  );
};

export { AdditionalInfo };
