import styles from "./QuoteCard.module.scss";
import { Button, Text } from "@ilc-technology/luik";
import { PaymentInfoStatus, SalesItemType, SegmentType } from "../../../Common/Types";
import Enrich from "../../../Common/services/TextEnricher";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { QuoteData, useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import React, { useMemo } from "react";
import { AppConfig } from "../../../AppConfig";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { addDays, formatDate } from "../../../Common/Helpers/DateHelper";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { ContentType, StandardTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { getPaymentOrReservation } from "../../../Common/services/PaymentsService";
import TBCardStatus, { TBCardStatusAlignment, TBCardStatusPosition } from "../../BaseComponents/TBCardStatus";
import { ColorVariant } from "../../../Common/services/ColorService";
import { getDestinationName } from "../../../Common/services/QuoteService";

interface QuoteCardProps {
  quote: QuoteData;
}

const QuoteCard: React.FC<QuoteCardProps> = ({ quote }) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();
  const { quotesContentCache, isQuotesDestinationsLoading } = useQuoteContext();
  const { id, segments, payments } = quote.quoteData;
  const quoteUrl = !id ? "" : `/quote/${id}`;
  const courseSegments = quote.quoteData.segments.filter((segment) => segment.type === SegmentType.Course);
  const firstDestinationCode = courseSegments[0].destinationCode;

  const allDestinationNames = useMemo(
    () =>
      !isQuotesDestinationsLoading &&
      courseSegments.map((segment) => quotesContentCache.destinations.get(segment.destinationCode)?.name),
    [isQuotesDestinationsLoading]
  );

  const destinationLabel = useMemo(
    () =>
      !isQuotesDestinationsLoading && allDestinationNames
        ? getDestinationName(courseSegments, quotesContentCache.destinations, " & ")
        : "",
    [isQuotesDestinationsLoading, allDestinationNames]
  );

  const bannerImage = useMemo(
    () =>
      !isQuotesDestinationsLoading
        ? quotesContentCache.destinations.get(firstDestinationCode)?.heroImage ?? AppConfig.content.defaultBannerImage
        : "",
    [isQuotesDestinationsLoading]
  );

  const navigate = useNavigate();

  const startDate = formatDate(segments[0].startAt, language, true);
  const endDate = formatDate(segments[segments.length - 1].endAt, language, true);

  const courses = segments
    .filter((s) => s.type === SegmentType.Course)!
    .map((s) => s.lineItems.find((li) => li.type === SalesItemType.Course));
  const courseDescriptions = courses.map((s) => s?.description).join(" & ");
  const courseDuration = courses.reduce((acc, val) => acc + (val?.quantity ?? 0), 0);
  const weeksLabel =
    courseDuration === 1
      ? labels[LabelKey.durationWeek]
      : courseDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];

  const isQuotePaidOrReserved =
    payments && payments.find((x) => x.status === PaymentInfoStatus.Reserve || x.status === PaymentInfoStatus.Paid);
  const payment = getPaymentOrReservation(payments);

  const paymentMadeAt = payment?.madeAt
    ? payment?.status === PaymentInfoStatus.Paid
      ? formatDate(payment?.madeAt, language)
      : formatDate(addDays(new Date(payment?.madeAt), 1).toString(), language)
    : undefined;

  const enricherContext = isQuotePaidOrReserved
    ? {
        currentQuote: quote,
        paymentStatus: payment?.status,
        paymentMadeAt: paymentMadeAt,
      }
    : {
        currentQuote: quote,
        paymentStatus: "",
        paymentMadeAt: undefined,
      };
  const paymentInfoLabel = "paymentInfo_";

  const isMultiQuote = courses.length != 1;
  const bgColorStyle =
    isQuotePaidOrReserved && payment?.status == PaymentInfoStatus.Reserve ? ColorVariant.Yellow : ColorVariant.Green;

  const handleLinkClick = (quoteUrl: string) => {
    trackEvent(quote.quoteData.opportunityUuid, StandardTrackingEvent.SelectContent, {
      content_type: ContentType.QuoteDetails,
      content_id: id,
    });
    navigate(quoteUrl);
  };

  return (
    <div data-testid="quote-card" onClick={() => handleLinkClick(quoteUrl)} className="a-hover-scale">
      {isQuotePaidOrReserved && (
        <TBCardStatus
          position={TBCardStatusPosition.OverCard}
          variant={bgColorStyle}
          alignment={TBCardStatusAlignment.Center}
          size="sm"
        >
          <Text variant="label-sm-bold">{Enrich(labels[paymentInfoLabel + payment?.status], enricherContext)}</Text>
        </TBCardStatus>
      )}
      <div
        className={`a-list-card-h shadow-card ${isQuotePaidOrReserved ? "rounded-b-lg" : "a-rounded"} ${styles.containerGrid}`}
      >
        <div className={styles.firstColumn}>
          {bannerImage ? (
            <img className={styles.destinationImage} src={bannerImage} />
          ) : (
            <div className={styles.destinationImage} />
          )}
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.courseInformation}>
            <label className={`${styles.duration} text-gray-45`}>
              {startDate} - {endDate} | {Enrich(weeksLabel, { numWeeks: courseDuration }).toUpperCase()}
            </label>
            <h3 className={styles.destination}>{destinationLabel}</h3>
            {!isMultiQuote && <Text variant="paragraph-small-body">{courseDescriptions}</Text>}
          </div>
          <div className={styles.priceInformation}>
            <Text variant="label-lg-bold" as="p">
              {parse(
                Enrich(
                  labels[LabelKey.quotePriceTotal]?.replace(/(\{quoteTotalPrice\})/g, "<nobr>$1</nobr>"),
                  enricherContext
                )
              )}
            </Text>
            <Text variant="label-sm-bold" className="text-gray-45" as="p">
              {Enrich(labels[LabelKey.quotePricePerWeek], enricherContext)}
            </Text>
          </div>
          <div className={styles.quoteButton}>
            <Button
              size="sm"
              intent="secondary-black"
              onPress={() => {
                handleLinkClick(quoteUrl);
              }}
            >
              {labels[LabelKey.showDetails]}
            </Button>
          </div>
          <div className={`${styles.quoteValidityInformation} text-gray-45`}>
            <Text variant="paragraph-caption" className="text-gray-45" as="p">
              {Enrich(labels[LabelKey.quoteValidUntil], {
                quoteValidToDate: formatDate(quote?.quoteData?.validUpTo, language, true),
              })}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteCard;
