import { ImportMarketFontCSS, Text } from "@ilc-technology/luik";
import React from "react";
import { ErrorDetails, StringIndexableDictionary } from "../../Common/Types";
import {
  getErrorDetailsToDisplay,
  getErrorMessageToDisplay,
  getErrorTitleToDisplay,
} from "../../Common/services/ErrorService";
import { AdditionalInfo } from "./AdditionalInfo";

interface ContentErrorProps {
  errorDetails: ErrorDetails;
  labels: StringIndexableDictionary<string>;
}

const ContentError: React.FC<ContentErrorProps> = ({ errorDetails, labels }) => {
  const title = getErrorTitleToDisplay(errorDetails, labels);
  const message = getErrorMessageToDisplay(errorDetails, labels);
  const additionalInfo = getErrorDetailsToDisplay(errorDetails);
  return (
    <div className="flex h-full items-center justify-center" data-testid={errorDetails.code}>
      <ImportMarketFontCSS market={"en"} />
      <div className="flex h-full max-w-[75%] flex-col items-center justify-center">
        <Text as="h1" variant="heading-5-bold" className="mb-2" data-testid="error-page-title">
          {title}
        </Text>
        {message.length > 0 && (
          <Text as="p" variant="paragraph-body" data-testid="error-page-message">
            {message}
          </Text>
        )}
        <AdditionalInfo additionalInfo={additionalInfo} />
      </div>
    </div>
  );
};

export { ContentError };
