import styles from "./NavigationLink.module.scss";
import { useLocation, Link } from "react-router-dom";
import React from "react";
import { Text } from "@ilc-technology/luik";

interface NavigationLinkProps {
  url: string;
  title: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ url, title }) => {
  const location = useLocation();
  const isSelected = location.pathname.endsWith(url);
  const textVariant = isSelected ? "label-md-bold" : "label-md";
  return (
    <div className={styles.navigationItemContainer}>
      <div className={styles.selectionMarker} />
      {url && (
        <Link to={url} className={`${styles.navigationItem}`}>
          <Text variant={textVariant}>{title}</Text>
        </Link>
      )}
    </div>
  );
};

export default NavigationLink;
