import React from "react";

interface PageLayoutProps {
  banner?: React.ReactNode;
  topStaticCard?: React.ReactNode;
  sidePanel?: React.ReactNode;
  children: React.ReactNode;
  bottomFullScreenPanel?: React.ReactNode;
  panelMinimizedOnLg?: boolean;
  stickySidePanel?: boolean;
  fullSizePanelShownFirst?: boolean;
  testId?: string;
}

const ContentLayout: React.FC<PageLayoutProps> = ({
  banner,
  topStaticCard,
  children,
  sidePanel,
  bottomFullScreenPanel,
  panelMinimizedOnLg = true,
  stickySidePanel = true,
  fullSizePanelShownFirst = false,
  testId = "content-layout",
}) => {
  const twForSticky = stickySidePanel ? `top-6 lg:mt-0 lg:sticky` : "";
  return (
    <>
      {banner}
      <div className="flex flex-col" data-testid={testId}>
        <div className={`a-page-content ${banner ? "a-content-offset" : ""} z-[1] flex w-full flex-col`}>
          {topStaticCard}
        </div>
        <div className="a-page-content a-gap a-card-py flex w-full flex-col lg:flex-row-reverse">
          {sidePanel && (
            <div className={`${fullSizePanelShownFirst ? "order-1 lg:order-1" : "order-3 lg:order-1"}`}>
              <div
                className={`a-gap flex flex-col ${twForSticky} ${panelMinimizedOnLg && "lg:min-w-side-bar lg:max-w-side-bar lg:self-start"}`}
              >
                {sidePanel}
              </div>
            </div>
          )}
          <div className="order-2 flex w-full flex-col">{children}</div>
        </div>
      </div>
      {bottomFullScreenPanel}
    </>
  );
};

export default ContentLayout;
