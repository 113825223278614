export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

export const nameofFactory = <T>() => {
  return new Proxy(
    {},
    {
      get: (_, prop) => prop,
    }
  ) as {
    [K in keyof T]: K;
  };
};
