import React from "react";
import styles from "./BannerImage.module.scss";
import { pathJoin } from "../../Common/utils";

interface BannerImageProps {
  url: string;
  focusPoint?: string;
  size?: "base" | "lg";
  hasOverlay?: boolean;
}

const BannerImage: React.FC<BannerImageProps> = ({ url, focusPoint, size = "base", hasOverlay = false }) => {
  const height = size === "base" ? "h-banner" : "lg:h-[600px] h-[667px]";

  if (!url) {
    return <div className={`bg-gray-300 w-full ${height}`}></div>;
  }

  const tryGetLongEdge = (url: string): string => {
    const match = url.match(/(\d+)x(\d+)/);
    const width = match ? parseInt(match[1]) : 0;
    const height = match ? parseInt(match[2]) : 0;

    return (width < height ? width : height).toString();
  };

  const edgeSize = tryGetLongEdge(url);
  const squareSize = edgeSize ? `${edgeSize}x${edgeSize}` : "";
  let portraitUrl = squareSize ? pathJoin([url, "/m/", squareSize]) : url;

  if (focusPoint) {
    portraitUrl = pathJoin([portraitUrl, `filters:focal(${focusPoint})`]);
  }

  return (
    <div className={`relative block w-full ${hasOverlay ? styles.imageOverlay : ""}`}>
      <picture>
        <source media="(orientation: portrait)" srcSet={portraitUrl} />
        <img alt="banner image" className={`w-full object-cover object-center ${height}`} src={url} />
      </picture>
    </div>
  );
};

export default BannerImage;
