import React from "react";
import { RichText, Text } from "@ilc-technology/luik";
import { ISbRichtext } from "@storyblok/react";

interface WhatsIncludedContentProps {
  content: string | ISbRichtext;
}

const WhatsIncludedContent: React.FC<WhatsIncludedContentProps> = ({ content }) => {
  const isRichText = typeof content === "object";

  return (
    <>
      {isRichText ? (
        <RichText richtext={content as ISbRichtext} />
      ) : (
        content && <Text variant="paragraph-body">{content as string}</Text>
      )}
    </>
  );
};

export default WhatsIncludedContent;
