import React from "react";
import { Text } from "@ilc-technology/luik";
import Enrich from "../../../Common/services/TextEnricher";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { TextVariants } from "../../../Common/LuikTypes";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface TitleBlok extends BlokBase {
  title: string;
  size: TextVariants;
}

interface TitleProps {
  blok: TitleBlok;
}

const Title: React.FC<TitleProps> = ({ blok }) => {
  const { quotes } = useQuoteContext();
  const enricherContext = {
    quotes: quotes.map((x) => x),
  };

  return (
    <>
      <div {...storyblokEditable(blok)} key={blok._uid}>
        <Text variant={blok.size}>{Enrich(blok.title, enricherContext)}</Text>
      </div>
    </>
  );
};

export default Title;
