import Surface from "../../Surface/Surface";
import { groupLineItems, LineItemGroup } from "../../../Common/groupLineItems";
import { Divider, Text } from "@ilc-technology/luik";
import styles from "./QuoteSegment.module.scss";
import { formatDateRange } from "../../../Common/Helpers/DateHelper";
import { FormatCurrency } from "../../../Common/Helpers/MoneyHelper";
import { LineItem, SegmentType } from "../../../Common/Types";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import React from "react";
import { LabelKey } from "../../../Common/StoryblokTypes";
import QuoteItem from "../QuoteGroup/QuoteItem/QuoteItem";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";

type QuoteSegmentProps = {
  segmentType: SegmentType;
  lineItems: Array<LineItem>;
  isMultiQuote: boolean;
  destinationName: string;
  duration: string;
  startAt: string;
  endAt: string;
  price: number;
  currency: string;
};

const QuoteSegment: React.FC<QuoteSegmentProps> = ({
  segmentType,
  lineItems,
  isMultiQuote,
  destinationName,
  duration,
  startAt,
  endAt,
  price,
  currency,
}) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();
  const defaultGroup: LineItemGroup = {
    [LabelKey.headerGeneralFees]: {
      displayOrder: 1,
      lineItems: lineItems,
    },
  };
  const groupedLineItems = segmentType === SegmentType.Course ? groupLineItems(lineItems) : defaultGroup;
  const numberOfGroups = Object.keys(groupedLineItems).length;

  return (
    <Surface>
      <div className={styles.quoteSegmentContainer}>
        {!isMultiQuote && (
          <div className={styles.priceDetailsTitle}>
            <Text variant="heading-5-bold" className={styles.priceDetailsTitle}>
              {labels[LabelKey.priceDetails]}
            </Text>
          </div>
        )}
        <div>
          {isMultiQuote && (
            <>
              {segmentType === SegmentType.Course ? (
                <div className={styles.priceHeader}>
                  <Text variant="heading-5-bold">{destinationName}</Text>
                  <Text variant="paragraph-body">
                    {formatDateRange(startAt, endAt, language)} | {duration}
                  </Text>
                  <div className={`${styles.rowSpaceBetween} ${styles.totalPrice}`}>
                    <Text variant="paragraph-body">{labels[LabelKey.total]}</Text>
                    <Text variant="label-lg-bold">{FormatCurrency(price, currency)}</Text>
                  </div>
                </div>
              ) : (
                <div className={styles.generalHeader}>
                  <Text variant="heading-5-bold">{labels[LabelKey.headerGeneralFees]}</Text>
                </div>
              )}
            </>
          )}

          {Object.keys(groupedLineItems).map((key, indexGroups) =>
            groupedLineItems[key].lineItems.map((item, indexItems) => {
              const numberOfGroupItems = groupedLineItems[key].lineItems.length;
              return (
                <div data-testid={`quoteItem-${item.sku}`} key={indexItems}>
                  <QuoteItem lineItem={item} />
                  {(numberOfGroups - 1 != indexGroups ||
                    (numberOfGroups - 1 == indexGroups && indexItems < numberOfGroupItems - 1)) && (
                    <Divider light className={styles.divider} />
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
    </Surface>
  );
};

export default QuoteSegment;
