import React, { useState, ReactNode, ReactElement, cloneElement, useEffect } from "react";
import Surface from "../Surface/Surface";
import { Text, Loading } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import Enrich from "../../Common/services/TextEnricher";
import Confetti from "react-confetti";
import { useWindowSize } from "../../Common/useWindowSize";
import { Quote } from "../../Common/Types";

interface MultiStepFormProps {
  id: string;
  children: ReactNode;
  onComplete: () => void;
}

interface CompleteFormProps {
  children: ReactNode;
}

export interface PropsFromStep {
  data: unknown;
  dirty: boolean;
  disabled: boolean;
  loading: boolean;
}

export interface MultiStepFormStepProps {
  onFormValuesChange?: (data: Partial<PropsFromStep>) => void;
  onNext?: () => void;
  onError?: () => void;
  complete?: boolean;
  quote: Quote;
}

interface MultiStepFormComponent extends React.FC<MultiStepFormProps> {
  Complete: React.FC<CompleteFormProps>;
}

const MultiStepForm: MultiStepFormComponent = (p: MultiStepFormProps) => {
  const { width, scrollHeight } = useWindowSize();
  const [keepSprinkling, setKeepSprinkling] = React.useState(false);
  const { labels } = useDatasources();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDirectionForward, setCurrentDirectionForward] = useState<boolean>(true);
  const [isCurrentStepComplete, setIsCurrentStepComplete] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isFirstStep, setIsFirstStep] = useState<boolean>(false);
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [steps, setSteps] = useState<ReactElement[]>(
    () =>
      React.Children.toArray(p.children).filter(
        (child) => (child as ReactElement & { type: { name: string } }).type.name !== Complete.name
      ) as ReactElement[]
  );
  const [completeStep, setCompleteStep] = useState<ReactElement[]>([]);
  const [saveButtonValue, setSaveButtonValue] = useState("");

  const confetti = (
    <Confetti
      width={width}
      height={scrollHeight}
      recycle={false}
      run={keepSprinkling}
      gravity={0.15}
      numberOfPieces={keepSprinkling ? 1000 : 0}
    />
  );

  useEffect(() => {
    setCompleteStep(
      React.Children.toArray(p.children).filter(
        (child) => (child as ReactElement & { type: { name: string } }).type.name === Complete.name
      ) as ReactElement[]
    );
    setSteps(
      React.Children.toArray(p.children).filter(
        (child) => (child as ReactElement & { type: { name: string } }).type.name !== Complete.name
      ) as ReactElement[]
    );
  }, [p.children]);

  useEffect(() => {
    if (isFirstStep || isComplete) {
      setKeepSprinkling(true);
    }
  }, [isFirstStep, isComplete]);

  useEffect(() => {
    if (isLastStep) {
      setSaveButtonValue(isFormDirty ? labels[LabelKey.save] : labels[LabelKey.continue]);
    } else {
      setSaveButtonValue(isFormDirty ? labels[LabelKey.saveAndContinue] : labels[LabelKey.continue]);
    }
  }, [isLastStep, isFormDirty]);

  const handleFormValuesChange = (data: PropsFromStep) => {
    setIsFormDirty(data.dirty);
    setIsFormDisabled(data.disabled);
    setIsFormLoading(data.loading);
  };

  const handleError = () => {
    setIsCurrentStepComplete(false);
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setIsCurrentStepComplete(false);
      setCurrentStep((prevStep) => prevStep - 1);
      setCurrentDirectionForward(false);
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setIsCurrentStepComplete(true);
      setCurrentDirectionForward(true);
    }
  };

  const handleNextCallback = () => {
    setIsCurrentStepComplete(false);
    if (currentDirectionForward && isLastStep) {
      setIsComplete(true);
    }

    if (currentDirectionForward && currentStep < steps.length) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else if (!currentDirectionForward && currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  useEffect(() => {
    setIsFirstStep(currentStep <= 0);
    setIsLastStep(currentStep >= steps.length - 1);
  }, [currentStep, steps]);

  useEffect(() => {
    if (currentStep >= steps.length) {
      setIsComplete(true);
    }
  }, [currentStep, steps]);

  useEffect(() => {
    if (p.onComplete && isComplete) {
      p.onComplete();
      setKeepSprinkling(true);
    }
  }, [isComplete]);

  const stepper = (
    <div id={p.id} data-testid="studentDetails-form" className="a-space-y-lg">
      {confetti}
      <Surface>
        <div className="mb-8">
          <Text variant="label-md" data-testid="steps-text">
            {Enrich(labels[LabelKey.studentUpdateSteps], {
              currentStep: currentStep + 1,
              stepsCount: steps.length,
            })}
          </Text>
        </div>
        <div>
          {steps.map((step, index) => {
            return index === currentStep ? (
              <div key={index}>
                {cloneElement(step, {
                  onFormValuesChange: handleFormValuesChange,
                  onNext: handleNextCallback,
                  onError: handleError,
                  complete: isCurrentStepComplete,
                })}
              </div>
            ) : null;
          })}
        </div>
      </Surface>
      <div className={"a-gap-sm sm:a-gap flex flex-col sm:flex-row sm:justify-between"}>
        {!isFirstStep ? (
          <input
            data-testid={`back-button`}
            className="a-cancel-button w-full"
            type="button"
            disabled={isFormLoading}
            onClick={handleBack}
            value={labels[LabelKey.back]}
          />
        ) : (
          <span className={"display: none"} />
        )}
        {isFormLoading ? (
          <div className={`a-save-button ${isFirstStep ? "w-auto" : "w-full"}`}>
            <Loading className="loading save-details-loading-spinner" size="lg" />
          </div>
        ) : (
          <input
            data-testid={`saveDetails-button`}
            className={`a-save-button ${isFirstStep ? "w-auto" : "w-full"}`}
            type="button"
            onClick={handleNext}
            disabled={isFormDisabled}
            value={saveButtonValue}
          />
        )}
      </div>
    </div>
  );

  return isComplete ? (
    <>
      {confetti}
      {completeStep}
    </>
  ) : (
    stepper
  );
};

const Complete = (p: CompleteFormProps) => {
  return <>{p.children}</>;
};

MultiStepForm.Complete = Complete;

export default MultiStepForm;
