import accounting from "accounting";
import { CURRENCY_MAPPING } from "../Constants";
import { Money } from "../Types";

/**
 * Formats a numeric amount with x decimal places together with a given currency,
 * eg. (1020, 2, USD) => $ 1,020.00. If currency isn't passed, default is $.
 * @param {number} amount
 * @param {number} precision
 * @param currency
 * @param showSignForPositiveValues forces showing + sign for positive values
 * @returns {string}
 */
export const FormatCurrency = (
  amount: number,
  currency: string,
  precision: number = 2,
  showSignForPositiveValues: boolean = false
) => {
  const prefix = showSignForPositiveValues && amount > 0 ? "+" : "";

  const currencyInfo = CURRENCY_MAPPING.find((c) => c.currencyCode == currency?.toUpperCase());
  const currencyFormatting = currencyInfo?.displayPosition == "after" ? `${prefix}%v %s` : `%s ${prefix}%v`;
  const formattedAmount = showSignForPositiveValues && amount > 0 ? `+${amount}` : amount;
  return accounting.formatMoney(formattedAmount, currencyInfo?.currencySymbol, precision, ",", ".", currencyFormatting);
};

export const FormattedMoney = (money: Money, precision: number = 2, showPlusForPositiveValues = false) => {
  return FormatCurrency(money.amount, money.currency, precision, showPlusForPositiveValues);
};
