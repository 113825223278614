import { CURRENCY_MAPPING } from "../Constants";
import { QuoteData } from "../../contexts/QuoteContext/QuoteContext";
import { FormatCurrency, FormattedMoney } from "../Helpers/MoneyHelper";
import { User } from "../../contexts/SessionContext/SessionContext";
import { Money, Segment } from "../Types";

export type SelectorData = {
  quotes?: Array<QuoteData>;
  currentQuote?: QuoteData;
  paymentStatus?: string;
  paymentMadeAt?: string;
  user?: User;
  destinationName?: string;
  numWeeks?: number;
  label?: string;
  segment?: Segment;
  firstName?: string;
  quoteValidToDate?: string;
  money?: Money;
  currentStep?: number;
  stepsCount?: number;
};

type SelectorCollection = { [key: string]: (data: SelectorData) => string };

const mapCurrencySymbol = (currency: string) => {
  return CURRENCY_MAPPING.find((c) => c.currencyCode == currency)?.currencySymbol ?? currency;
};

const selectors: SelectorCollection = {
  quoteCount: (data) => {
    if (data.quotes!.length == 0) return "";
    return `${data.quotes!.length}`;
  },
  quotePaymentDate: (data) => `${data.paymentStatus}`,
  quoteTotalPrice: (data) => `${FormattedMoney(data.currentQuote!.quoteData.prices.totalPrice)}`,
  quoteWeeklyPrice: (data) => {
    const pricePerWeek =
      data.currentQuote!.quoteData.prices.totalPrice.amount /
      data.currentQuote!.quoteData.segments?.reduce((acc, val) => acc + val.duration.value, 0);
    return `${FormatCurrency(Math.round(pricePerWeek), data.currentQuote!.quoteData.prices.totalPrice.currency, 0)}`;
  },

  quoteCurrency: (data) => {
    return `${mapCurrencySymbol(data.currentQuote!.quoteData.prices.totalPrice.currency)}`;
  },
  quoteReservationPrice: (data) => {
    if (data.currentQuote?.reservationPrice) return `${FormattedMoney(data.currentQuote!.reservationPrice)}`;
    return "";
  },
  userName: (data) => `${data.user?.firstName}`,
  salesUserName: (data) => `${data.firstName}`,
  destinationName: (data) => `${data.destinationName}`,
  numWeeks: (data) => `${data.numWeeks}`,
  paymentMadeAt: (data) => `${data.paymentMadeAt}`,
  termsAndConditions: (data) => `${data.label}`,
  segmentTotalPrice: (data) => `${FormattedMoney(data.segment!.price.totalPrice)}`,
  segmentPricePerWeek: (data) => {
    const pricePerWeek = data.segment!.price.totalPrice.amount / data.segment!.duration.value;
    return `${FormatCurrency(Math.round(pricePerWeek), data.segment!.price.totalPrice.currency)}`;
  },
  quoteValidToDate: (data) => `${data.quoteValidToDate}`,
  money: (data) => `${FormattedMoney(data.money!)}`,
  privacyPolicy: (data) => `${data.label}`,
  currentStep: (data) => `${data.currentStep}`,
  stepsCount: (data) => `${data.stepsCount}`,
};

/**
 * Parses provided string looking for magical {someName} tokens and resolves proper value for a given key
 * @param {string} text
 * @param {SelectorData} data
 * @returns {string}
 */
const Enrich = (text: string | undefined, data: SelectorData): string => {
  if (!text) {
    return "";
  }
  return (
    text
      .match(/\{\w+\}/g)
      ?.map((m) => m.slice(1, -1))
      .reduce((acc, val) => acc.replace(`{${val}}`, selectors[val]?.(data) ?? ""), text) ?? text
  );
};

export default Enrich;
